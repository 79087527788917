export const EXTRA_PDF_FONT_NAME = 'pdfFont'; 

//Fixes a bug on iOS with position:fixed on rotate from landscape to portrait
export const correctFixedPositionOnRotate = (ref: any) => {
    if (ref && ref.style) {
        ref.style.setProperty('position', 'absolute');
        setTimeout(() => {
            ref.style.setProperty('position', 'fixed');
        }, 100);
    }
}

export const getPDFFont = (useExtraFont: boolean) => {    
    if (useExtraFont) {
        return EXTRA_PDF_FONT_NAME;
    }
    
    return 'helvetica';
}