
import {Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import {FileOpener} from '@ionic-native/file-opener';
import {store} from '@/store';
import {addFileUri, clearFileUris, getFileUris} from '@/storage/DataStorage';
import {AlertBuilder} from '@/utilities/AlertBuilder';
import {createLoadSpinner} from '@/services/ShareService';
 import {
    DeleteApp,
    StorageLocationPicker as StorageLocationPickerPlugin,
} from '@/delete-app-plugin/definitions';
import {FileSharer} from '@byteowls/capacitor-filesharer';


const checkPermissions = async () => {
    const permissionStatus = await Filesystem.checkPermissions();
    return permissionStatus.publicStorage === 'granted';
  };
  
const requestPermissions = async () => {
    const permissionStatus = await Filesystem.requestPermissions();
    return permissionStatus.publicStorage === 'granted';
};

export async function  checkAndRequestStoragePermission() {
    if (!(await checkPermissions())) {
      return await requestPermissions();
    }
    return true;
}

async function handleIosFileSave(filename: string, content: string, plainText: boolean, mimeType: string) {
    const file = await Filesystem.writeFile({
        path: `${filename}`,
        data: content,
        encoding: plainText ? Encoding.UTF8 : undefined,
        directory: Directory.Cache,
    });
    await FileOpener.showOpenWithDialog(file.uri, mimeType);
}

async function handleAndroidFileSave(filename: string, content: string, plainText: boolean, mimeType: string, useDeviceApk: boolean) {
    if(await checkAndRequestStoragePermission()){
        await store.dispatch("setShouldExitApp", false);
        const { uri } = await StorageLocationPickerPlugin.save({ filename: filename, content: content, plainText: plainText, mimeType: mimeType, useDeviceApk: useDeviceApk });
        await addFileUri(uri);
        await store.dispatch("setShouldExitApp", true);
    }
}

export async function handleAndroidFileSelectToDelete(mimeType: string) {
    
    if(await checkAndRequestStoragePermission()){
        await store.dispatch("setShouldExitApp", false);
        const { uri } = await StorageLocationPickerPlugin.navigate({ mimeType: mimeType});
        await StorageLocationPickerPlugin.delete({uris: [uri]});
        await store.dispatch("setShouldExitApp", true);
    }   
}

export async function deleteAndroidFiles() {
    const loading = await createLoadSpinner();
    const language = store.getters.getLocaleMessages();
    await loading.present();
    const uris = await getFileUris();
    try {
        await StorageLocationPickerPlugin.delete({uris: uris});
        await clearFileUris();
    } catch {
        const alert = new AlertBuilder('custom-alert', language.application.errorDeletingFiles).addCancelButton();
        alert.present();
    } finally {
        await loading.dismiss();
    }
}

export async function writeFile(filename: string, content: string, plainText: boolean, mimeType: string, useDeviceApk: boolean) {
    if (store.getters.platform() === "ios") {
        await handleIosFileSave(filename, content, plainText, mimeType);
    } else if (store.getters.isPwa()) {
        await shareFile(filename, content, plainText, mimeType);
    } else {
        if(await checkAndRequestStoragePermission()){
            await handleAndroidFileSave(filename, content, plainText, mimeType, useDeviceApk);
        }
    }
}

export async function writeFileToDataDirectory(fileName: string, data: string) {
    try {
        await Filesystem.writeFile({
            path: fileName,
            data: data,
            directory: Directory.Data,
            encoding: Encoding.UTF8
        });
        try {
            const result = await Filesystem.readdir({
              path: '', // Root path of the directory
              directory: Directory.Data
            });
          } catch (e) {
            console.error('Debug: Unable to read directory', e);
        }
    } catch (error) {
        console.error('Debug: Error writing file to data directory', error);
    }
}

export async function writeFileToDocumentsDirectory(fileName: string, data: string) {
    if(await checkAndRequestStoragePermission()){
        try {
            await Filesystem.writeFile({
                path: fileName,
                data: data,
                directory: Directory.Documents,
                encoding: Encoding.UTF8
            });
            try {
                const result = await Filesystem.readdir({
                path: '', // Root path of the directory
                directory: Directory.Data
                });
            } catch (e) {
                console.error('Debug: Unable to read directory', e);
            }
        } catch (error) {
            console.error('Debug: Error writing file to data directory', error);
        }
    }   
}


export async function dataDirectoryFileExists(filename: string) {
    try {
        await Filesystem.stat({
          path: filename,
          directory: Directory.Data
        });
        return true;
      } catch (error: any) {
        if (error.message === 'File does not exist') {
          return false;
        } else {
          console.error('Debug: Error checking file existence:', error);
          return false;
        }
    }
}

export async function readJSONFileFromDataDirectory(filename: string, plainText: boolean) {
    try {
        const result = await Filesystem.readFile({
            path: filename,
            directory: Directory.Data,
            encoding: Encoding.UTF8
        });

        let jsonData: string;

        if (result.data instanceof Blob) {
            // Convert Blob to string
            const text = await result.data.text();
            jsonData = text;
        } else {
            jsonData = result.data; // Already a string
        }
        const fileJson = await JSON.parse(jsonData);
        return fileJson;
    } catch (error) {
        console.error('Error reading local json file:', error);
        return {};
    }
}

//TODO: Look for replacement method / library as byteowls package is no longer being updated and considered deprecated
/**
 * Deprecated 
 */
export async function shareFile(filename: string, content: string, plainText: boolean, mimeType: string) {
    await FileSharer.share({
        filename: filename,
        base64Data: plainText ? btoa(content) : content,
        contentType: mimeType,
    });
}

export async function deleteApp() {
    if (store.getters.platform() === "android") {
        await deleteAndroidFiles();
    }
    await DeleteApp.deleteApp();
}

  