export function getVideoDataUri(content: string | undefined) {
    return getDataUri('data:video/mp4;base64', content);
}

export function getImageDataUri(content: string | undefined) {
    return getDataUri('data:image/svg+xml;base64', content);
}

export function getAudioDataUri(content: string | undefined) {
    return getDataUri('data:audio/mp3;base64', content);
}

function getDataUri(prefix: string, content: string | undefined) {
    if (!content) {
        return '';
    }
    return content.startsWith('data:') ? content : `${prefix}, ${content}`;
}

export function formatDate(date: Date): string {
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}`;
}