import {
    close,
    shareSocial,
    save,
    musicalNotes,
    document,
    people,
    logoAppleAppstore,
    logoGooglePlaystore,
    folder, trash,
} from 'ionicons/icons';
import {ActionSheetOptions, ActionSheetButton} from "@ionic/core";
import {actionSheetController} from "@ionic/vue";
import {store} from "@/store";
import {
    shareApp,
    share,
    shareJourneySet,
    shareAudio,
    saveAudioToDevice,
    saveJourneySetToDevice, saveApp
} from "@/services/ShareService";
import {getJourneySet, Journey} from "@/storage/DataStorage";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {Share} from '@capacitor/share';

export class ActionSheetBuilder {
    actionSheetOptions = { cssClass: "custom-action-sheet" } as ActionSheetOptions;
    private language = store.getters.getLocaleMessages();
    
    constructor(header: string) {
        this.actionSheetOptions.header = header;
        this.actionSheetOptions.buttons = [] as ActionSheetButton[];
    }
    
    async shareAppStore(path: string) {
        if (store.getters.isPwa()) {
            navigator.clipboard.writeText(path).then(() => {
                const alert = new AlertBuilder('custom-alert', this.language.application.copiedToClipboard).addOkButton();
                alert.present();
            });
        } else {
            await Share.share({
                url: `${path}`,
            });
        }
    }
    
    async shareAppleStore() {
        await this.shareAppStore(this.language.application.appleStoreLink);
    }

    async shareGoogleStore() {
        await this.shareAppStore(this.language.application.googleStoreLink);
    }

    addShareWithOthers(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: shareSocial,
            text: this.language.application.shareWithOthers,
            handler: () => handler()
        });
        return this;
    }

    addSaveToDevice(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: save,
            text: store.getters.isPwa() ? this.language.application.saveApp : this.language.application.saveToDevice,
            handler: () => handler()
        });
        return this;
    }

    addSaveAudio(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: musicalNotes,
            text: this.language.application.saveAudio,
            handler: () => handler()
        });
        return this;
    }

    addShareAudio(journey: Journey, currentSetId: string, audioFileId: any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: musicalNotes,
            text: this.language.application.shareAudio,
            handler: async () => {
                const actionSheetBuilder = new ActionSheetBuilder(journey.name);
                await actionSheetBuilder.buildShareOrSaveAudio(currentSetId, audioFileId, journey.order);
                const actionSheet = await actionSheetBuilder.create();
                await actionSheet.present();
            }
        });
        return this;
    }

    addSavePdf(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: document,
            text: this.language.application.savePDF,
            handler: () => handler()
        });
        return this;
    }

    addSharePdf(name: string, shareHandler: () => any, saveHandler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: document,
            text: this.language.application.sharePDF,
            handler: async () => {
                const actionSheetBuilder = new ActionSheetBuilder(name);
                await actionSheetBuilder.buildShareOrSavePdf(shareHandler, saveHandler);
                const actionSheet = await actionSheetBuilder.create();
                await actionSheet.present();
            }
        });
        return this;
    }

    addAppOptions(shareOnly: boolean) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: people,
            text: shareOnly ? this.language.application.shareAppOnly : this.language.application.shareApp,
            handler: async () => {
                await actionSheetController.dismiss();
                const actionSheetBuilder = new ActionSheetBuilder('');
                await actionSheetBuilder.buildShareOrSaveApp();
                const actionSheet = await actionSheetBuilder.create();
                await actionSheet.present();
            }
        });
        return this;
    }

    addAppleStoreShare() {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: logoAppleAppstore,
            text: this.language.application.appleStoreShare,
            handler: async () => await this.shareAppleStore()
        });
        return this;
    }

    addGoogleStoreShare() {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButtonBlack",
            icon: logoGooglePlaystore,
            text: this.language.application.googleStoreShare,
            handler: async () => await this.shareGoogleStore()
        });
        return this;
    }
    
    async addStoreLinks() {
        //show Google link first on Android or web
        if (store.getters.platform() !== "ios" && this.language.application.googleStoreLink) {
            this.addGoogleStoreShare();
        }
        if (store.getters.platform() !== "ios" && this.language.application.appleStoreLink) {
            this.addAppleStoreShare();
        }
        
        //Show Apple app store link first on iOS
        if (store.getters.platform() === "ios" && this.language.application.appleStoreLink) {
            this.addAppleStoreShare();
        }
        if (store.getters.platform() === "ios" && this.language.application.googleStoreLink) {
            this.addGoogleStoreShare();
        }
        return this;
    }
    
    addSaveJourneySet(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButton",
            icon: folder,
            text: this.language.application.saveJourneySet,
            handler: () => handler()
        });
        return this;
    }

    addShareJourneySet(name: string, id: string) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButton",
            icon: folder,
            text: this.language.application.shareJourneySet,
            handler: async () => {
                const actionSheetBuilder = new ActionSheetBuilder(name);
                await actionSheetBuilder.buildShareOrSaveJourneySet(id);
                const actionSheet = await actionSheetBuilder.create();
                await actionSheet.present();
            }
        });
        return this;
    }
    
    addDeleteJourneySet(handler: () => any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButton",
            icon: trash,
            text: this.language.application.deleteJourneySet,
            handler: () => handler()
        });
        return this;
    }
    
    addClose() {
        this.actionSheetOptions.buttons.push({
            text: this.language.application.close,
            icon: close
        });
        return this;
    }
    
    async buildShareOrSaveApp() {
        this.actionSheetOptions.header = store.getters.platform() === "ios" || this.language.buildConfigs.config.appStoreBuild === 'true' ? this.language.application.shareAppOnly : this.language.application.shareApp;
        if (store.getters.platform() === "android" && this.language.buildConfigs.config.appStoreBuild == 'false') {
            this.addShareWithOthers(async () => {
                await share(async () => await shareApp());
            })
        } 
        if (store.getters.isPwa() || this.language.buildConfigs.config.appStoreBuild == 'false') {
            this.addSaveToDevice(async () => await saveApp())
        }
        await this.addStoreLinks();
        return this.addClose();
    }

    async buildShareOrSaveJourneySet(id: string) {
        await actionSheetController.dismiss();
        this.addShareWithOthers(async () => {
            await share(async () => await shareJourneySet(id));
        }).addSaveToDevice(async () => {
            await saveJourneySetToDevice(id);
        });
        return this.addClose();
    }

    async buildShareOrSaveAudio(currentSetId: string, audioFileId: string, order: number) {
        await actionSheetController.dismiss();
        this.addShareWithOthers(async () => {
            await share(async () => {
                const journeySet = await getJourneySet(currentSetId);
                await shareAudio(audioFileId, `${journeySet.pdfName}_${order}`);
            }, true);
        }).addSaveToDevice(async () => {
            const journeySet = await getJourneySet(currentSetId as string);
            await saveAudioToDevice(audioFileId, `${journeySet.pdfName}_${order}`);
        });
        return this.addClose();
    }

    async buildShareOrSavePdf(sharePdf: () => any, savePdf: () => any) {
        await actionSheetController.dismiss();
        this.addShareWithOthers(async () => {
            await share(sharePdf, true);
        }).addSaveToDevice(async () => savePdf());
        return this.addClose();
    }

    async create() {
        return await actionSheetController.create(this.actionSheetOptions);
    }
    
    addUpdate(handler: () => any, icon: any) {
        this.actionSheetOptions.buttons.push({
            cssClass: "customASButton",
            icon: icon,
            text: this.language.application.updateAvailable,
            handler: () => handler()
        });
    }
}