import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_popup_slides = _resolveComponent("popup-slides")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, { translucent: "" }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, {
          class: "modal-toolbar",
          mode: "md"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.dismiss }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("messages.application.close")) + " ", 1),
                    _createVNode(_component_ion_icon, {
                      src: _ctx.close,
                      style: {"font-size":"20px"}
                    }, null, 8, ["src"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_base_layout, {
      shouldShowSubHeader: false,
      shouldShowCalculatorIconOnInstance: _ctx.shouldShowCalculatorIconOnInstance
    }, {
      default: _withCtx(() => [
        (_ctx.showSlides)
          ? (_openBlock(), _createBlock(_component_popup_slides, {
              key: 0,
              slideContents: _ctx.slideContents
            }, null, 8, ["slideContents"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_1))
      ]),
      _: 1
    }, 8, ["shouldShowCalculatorIconOnInstance"])
  ], 64))
}