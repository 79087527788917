import axios, {AxiosResponse, ResponseType} from 'axios';
import {
    /* saveJourney,
    saveJourneySet,
    saveMedia,*/
    JourneySetToDisplay, saveJourneySetAndData,
} from '@/storage/DataStorage';
/* import { v4 as uuidv4 } from "uuid";*/
import {createLoadSpinner} from "@/services/ShareService";
import {store} from "@/store";
import {FileIdentifiers, MimeTypes} from "@/types/types";
import {writeFile} from "@/utilities/Filesystem";

const USERNAME = "generalUser123";
const PASSWORD = "U8CGKbJJa9e64mGt";

async function getJourneySetMetadata(): Promise<any> {
    const messages = await store.getters.getLocaleMessages();
    const metadata = await axios.get(`https://${ messages.application.downloadDomain }/${ messages.language.variant }/journeySetMetadata.json?timestamp=${new Date().getTime()}`, {
        auth: {
            username: USERNAME,
            password: PASSWORD,
        }
    }).catch(function (){
        return undefined;
    });
    
    return metadata?.data ?? [];
}

export function getAWSUrl(rootFolder: string, key: string, useHost = false): string {
    let fetchUrl = '';
    let host = '';
    if (store.getters.isPwa()) {
        host = window.location.hostname;
        if (host === "localhost") {
            fetchUrl = `https://testdiscoverystudies.net/${rootFolder}/${key}`;
        }
        else if (useHost) {
            const hostArray = host.split(".");
            hostArray.shift();
            fetchUrl = `https://${ hostArray.join(".") }/${ rootFolder }/${ key }`;
        }
    }
    if (!host || (host !== 'localhost' && !useHost)){
        fetchUrl = `https://${ store.state.downloadDomain }/${ rootFolder }/${ key }`;
    }
    
    return fetchUrl;
}

export async function getFromS3(rootFolder: string, key: string, responseType: ResponseType = 'json', useHost = false): Promise<AxiosResponse> {
    const fetchUrl = getAWSUrl(rootFolder, key, useHost);
    store.dispatch("setIsInvalidUrl", false);
    return await axios.get(fetchUrl, {
        responseType : responseType,
        auth: {
            username: USERNAME,
            password: PASSWORD,
        }
    }).catch(function (e) {
        if (e.response.status === 403 || e.response.status === 404) {
            store.dispatch("setIsInvalidUrl", true);
        } else {
            store.dispatch("setIsInvalidUrl", false);
        }
        return {data: responseType === 'json' ? {} : ''} as AxiosResponse;
    });
}
// Only journeysets ingested through manifest file updates should be marked as isDefault as it is used to drive background loading
export async function getDownloadableJourneySets(quadrantId: string): Promise<JourneySetToDisplay[]> {
    const journeySetMetadata = await getJourneySetMetadata();
    const journeySetsToDownload = [] as JourneySetToDisplay[];
    for (const journeySet of journeySetMetadata) {
        if (journeySet["quadrantId"] === quadrantId) {
            journeySetsToDownload.push({
                id: journeySet["guid"],
                name: journeySet["setname"],
                quadrantId: quadrantId,
                numberOfJourneys: parseInt(journeySet["numberofjourneys"]),
                icon: journeySet["icon"],
                order: journeySet["order"],
                published: journeySet["published"],
                isDefaultSet: false
            } as JourneySetToDisplay)
        }
    }
    return journeySetsToDownload;
}

function isDefault(journeySet: any) {
    if ((store.getters.isPwa() && journeySet['IsPwaDefault']) ||
        (store.getters.isSecure() && journeySet['IsSecureDefault']) ||
        (store.getters.isOnlineApp() && journeySet['IsAppDefault'])) {
        return true;
    }
    
    return false;
}

async function storeDownloadedJourneySet(journeySet: JourneySetToDisplay, quadrantId: string) {
    journeySet.isDefaultSet = isDefault(journeySet);
    journeySet.quadrantId = quadrantId;
    journeySet.numberOfJourneys = journeySet.journeys?.length || 0;
    journeySet.alreadyDownloaded = true;
    
    await saveJourneySetAndData(journeySet);
}

export async function downloadJourneySet  (journeySetId: string, quadrantId: string) {
    try {
        const messages = await store.getters.getLocaleMessages();
        const content = await getFromS3(messages.language.variant, `journeySet_${journeySetId.toUpperCase()}.json?timestamp=${new Date().getTime()}`);
        const { data: journeySet }: { data: JourneySetToDisplay } = content;
        if(!store.state.isInvalidUrl){
            await storeDownloadedJourneySet(journeySet, quadrantId);
        }
    }
    catch {
        return;
    }
}

export async function downloadJourneySetWithSpinner(journeySetId: string, quadrantId: string) {
    const loading = await createLoadSpinner();
    try {
        await loading.present();
        await downloadJourneySet(journeySetId, quadrantId);
    } finally {
        await loading.dismiss();
    }
}

export async function downloadAPK(appStoreVersion = false) {
    try {
        const messages = await store.getters.getLocaleMessages();
        let apkName = '';
        if (appStoreVersion) {
            apkName = `app_${ messages.language.variant.replace(/-/g, "") }_appStore.apk`;
        } else if (process.env.NODE_ENV === 'development') {
            apkName = `app_${ messages.language.variant.replace(/-/g, "") }_admin.apk`;
        } else {
            apkName = `${ FileIdentifiers.Apk }${ messages.application.appName }.apk`;
        }
        const apk = await getFromS3(messages.language.variant, apkName + `?timestamp=${new Date().getTime()}` , 'blob');
        const apkExportName = `${ messages.application.appName }${ FileIdentifiers.Apk }.apk`;

        const reader = new FileReader();
        await new Promise((resolve) => {
            reader.onloadend = async () => {
                const result = reader.result as string;
                const base64Data = result.split(',')[1];
                await writeFile(apkExportName, base64Data, false, MimeTypes.APK, false);
                resolve(true);
            };
            reader.readAsDataURL(apk.data);
        });
    } catch {
        return;
    }
}