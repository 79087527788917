<template>
    <ion-slides class="slides-container" pager="true" :options="slideOpts" @ionSlideWillChange="pauseAudio" ref="slides">
        <template v-for="slideContent of slideContents" :key="slideContent.headerText">
            <ion-slide>
                <div class="slide-container" v-show="showSlideContent">
                    <div class="slide-content" :style="slideContent.audioSrc ? 'height:calc(100% - 150px)' : 'height:calc(100% - 50px)'">
                        <img v-if="slideContent.imageSrc && !isVideo(slideContent.imageSrc)" :src="slideContent.imageSrc" alt=""/>
                        <video v-if="slideContent.imageSrc && isVideo(slideContent.imageSrc)" autoplay loop playsinline muted @loadedmetadata="metadataLoaded" @canplaythrough="fullyLoaded">
                            <source :src="slideContent.imageSrc" :type="getContentType(slideContent.imageSrc)">
                        </video>
                        <vue3-markdown-it :source='slideContent.contentText' breaks />
                    </div>
                    <div class="slide-footer" :style="slideContent.audioSrc ? 'height:150px' : 'height:40px'" ref="slideFooter">
                        <audio-player
                            :audio-data="slideContent.audioSrc"
                            :start-time-seconds="audioStartTime"
                            :end-time-seconds="audioEndTime"
                            color="--color-primary"></audio-player>
                    </div>
                </div>
            </ion-slide>
        </template>
    </ion-slides>
</template>

<script lang="ts">
import { IonSlides, IonSlide } from "@ionic/vue";
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import { useStore } from 'vuex';
import { SlideContent } from "@/types/types";
import AudioPlayer from '@/components/AudioPlayer.vue';
import {createLoadSpinner} from "@/services/ShareService";

export default defineComponent({
    name: "PopupSlides",
    components: {
        IonSlides,
        IonSlide,
        AudioPlayer
    },
    props: {
        slideContents: { type: Array as () => Array<SlideContent> },
    },
    setup(props) {
        const store = useStore();
        const slideFooter = ref();
        const showSlideContent = ref(false);
        const useOnLoadedMetadata = computed(() => {
            const isSafari = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1
            return isSafari || store.getters.platform() === 'ios';
        });
        const slideOpts = {
            initialSlide: 0,
            threshold: 12,
            pagination: {
                el: ".swiper-pagination",
                type: "bullets",
                clickable: true
            }
        };
        const slides = ref();
        const audioStartTime = ref(0);
        const audioEndTime = ref(0);
        const loadingSpinner = ref();
        const numVideos = props.slideContents?.filter((slide) => isVideo(slide.imageSrc ?? '')).length || 0;
        let numVideosLoaded = 0;

        onBeforeMount(async () => {
            if (numVideos > 0) {
                loadingSpinner.value = await createLoadSpinner(false);
                if (!showSlideContent.value) {
                    await loadingSpinner.value.present();
                }
            } else {
                showSlideContent.value = true;
            }
        })

        function pauseAudio() {
            store.dispatch("setShouldPauseAudio", true);
        }

        function metadataLoaded() {
            if (useOnLoadedMetadata.value) {
                videoLoaded();
            }
        }

        function fullyLoaded() {
            if (!useOnLoadedMetadata.value) {
                videoLoaded();
            }
        }

        function videoLoaded() {
            numVideosLoaded += 1;
            if (numVideos === numVideosLoaded) {
                showSlideContent.value = true;
                loadingSpinner.value?.dismiss();
            }
        }

        function isVideo(dataUrl: string) {
            return dataUrl.split(':')[1].split('/')[0] === 'video';
        }

        function getContentType(dataUrl: string) {
            return dataUrl.split(';')[0].split(':')[1];
        }

        return {
            videoLoaded,
            slideOpts,
            audioStartTime,
            audioEndTime,
            pauseAudio,
            slides,
            slideFooter,
            showSlideContent,
            useOnLoadedMetadata,
            metadataLoaded,
            fullyLoaded,
            isVideo,
            getContentType
        };
    },
});
</script>

<style scoped>
.slides-container {
    height: 100%;
    --bullet-background-active: var(--color-primary);;
}

.slide-container {
    height: 100%;
}
.slide-content {
    -webkit-transform: translate3d(0,0,0);
    display: flex;
    flex-direction: column;
    align-items: start;
    padding-top: calc(66px + var(--ion-safe-area-top));
    padding-left: 36px;
    padding-right: 36px;
    overflow-y: auto;
}

.slide-footer {
    width: 100%;
    background: var(--color-app-background);
}

ion-icon {
    width: 100%;
    color: var(--color-primary);
}

video {
    width: 100%;
    border-radius: 20px;
}
</style>
