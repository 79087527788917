<template>
    <ion-page id="main-content">
        <ion-content :fullscreen="true" class="base-content" :class="directionClass">
            <div class="sub-header" v-show="shouldShowSubHeader">
                <div class="sub-header__icon-row">
                    <ion-icon @click="routeBack()" :icon="chevronBack" class="sub-header__icon back-button" />
                    <ion-icon v-show="shouldShowShareIcon" @click="$emit('share')" :icon="shareSocial" class="sub-header__icon" />
                    <ion-icon v-if="shouldShowHelpIcon" @click="$emit('help')" :icon="help" class="sub-header__icon" />
                </div>
                <h1 v-if="subHeaderText.length > 0">{{ subHeaderText }}</h1>
            </div>
            <slot />
            <div v-if="shouldShowCalculatorIconOnInstance && shouldShowCalculatorIcon" v-on:click="routeToCalculator()" class="calculator-container" ref="calculatorContainer"><ion-icon :icon="keypad" /></div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage, IonIcon, modalController } from "@ionic/vue";
import { useRouter } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { chevronBack, help, shareSocial, keypad } from "ionicons/icons";
import translate from "@/views/translate";
import {correctFixedPositionOnRotate} from "@/utilities/Styling";

export default {
    name: "BaseLayout",
    props: {
        returnRoute: {
            type: String,
            default: "",
        },
        shouldShowSubHeader: {
            type: Boolean,
            default: true,
        },
        shouldShowShareIcon: {
            type: Boolean,
            default: false,
        },
        shouldShowHelpIcon: {
            type: Boolean,
            default: false,
        },
        shouldShowCalculatorIconOnInstance: {
            type: Boolean,
            default: true,
        },
        subHeaderText: {
            type: String,
            default: ""
        }
    },
    components: {
        IonContent,
        IonPage,
        IonIcon,
    },
    setup(props: any) {
        const router = useRouter();
        const store = useStore();
        const { t } = translate();
        const calculatorContainer = ref();
        const dismissModal = async () => {
            await modalController.dismiss();
        };
        const routeToCalculator = async () => {
            const isModalOnScreen = (await modalController.getTop()) !== undefined;
            if (isModalOnScreen) {
                await dismissModal();
            }
            router.push({ name: "calculator" });
        };
        const directionClass = computed(() => t("messages.language.textDirection"));
        const routeBack = async () => {
            router.push(props.returnRoute);
        };
        const shouldShowCalculatorIcon = computed(() => store.state.shouldShowCalculatorIcon);

        onMounted(() => {
            window.addEventListener('resize', () => correctFixedPositionOnRotate(calculatorContainer.value));
        });
        
        return { 
            routeToCalculator, 
            directionClass, 
            chevronBack, 
            shareSocial, 
            help, 
            routeBack, 
            shouldShowCalculatorIcon,
            keypad,
            calculatorContainer
        };
    },
};
</script>

<style scoped>
ion-menu-button {
    color: var(--ion-color-primary);
}

.base-content {
}

.base-content::part(background) {
    --background: var(--color-app-background);
}

#container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
}

#container strong {
    font-size: 20px;
    line-height: 26px;
}

#container p {
    font-size: 16px;
    line-height: 22px;
    color: var(--ion-color-custom-step-550);
    margin: 0;
}

#container a {
    text-decoration: none;
}

.calculator-container ion-icon {
    font-size: 33px;
    color: var(--ion-color-custom-step-1000);
}

.calculator-container {
    z-index: 1;
    bottom: 104px;
    background: var(--ion-color-custom-step-450) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px var(--box-shadow);
    border-radius: 6px 0px 0px 6px;
    padding: 4px 0;
    position: fixed;
    display: flex;
    align-items: center;
    transform: translateX(991px);
}
.ios .calculator-container {
    transform: none;
    right: 0;
}

.rtl .calculator-container {
    border-radius: 0px 6px 6px 0px;
    transform: translateX(-991px);
}
.ios .rtl .calculator-container {
    transform: none;
    left: 0;
}

@media (max-width: 1023px) {
    .calculator-container {
        right: 0;
        transform: none;
    }
    .rtl .calculator-container {
        right: calc(100% - 33px);
        transform: none;
    }
}

.sub-header {
    position: sticky;
    top: calc(56px + var(--ion-safe-area-top));
    background: var(--color-app-background);
    z-index: 2;
}

.sub-header__icon-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 11px 11px 11px;
    height: 56px;
}

.sub-header__icon {
    font-size: 30px;
    color: var(--color-text-gray);
}

h1 {
    font-size: 16px;
    color: var(--color-text-gray);
    margin: 0 auto;
    padding: 0;
    text-align: center;
    padding-bottom: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.rtl h1 {
    text-align: right;
    margin-right: 20px;
}
</style>
