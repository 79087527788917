import { registerPlugin } from '@capacitor/core';

export interface DeleteAppPluginPlugin {
  echo(options: { value: string }): Promise<{ value: string }>;
  deleteApp(): void;
}

const DeleteApp = registerPlugin<DeleteAppPluginPlugin>('DeleteAppPlugin', {
  web: () => import('./web').then(m => new m.DeleteAppPluginWeb()),
});

export interface ShareAppPluginPlugin {
  shareApp(options: { appName: string }): Promise<void>;
}

const ShareApp = registerPlugin<ShareAppPluginPlugin>('ShareAppPlugin', {
  web: () => import('./web').then(m => new m.ShareAppPluginWeb()),
});

export interface ImportJourneySetPluginPlugin {
  resetUri(): Promise<void>;
  getJourneySetToImport(): Promise<{ uri: string }>;
  getFilename(): Promise<{ filename: string }>;
}


const ImportJourneySet = registerPlugin<ImportJourneySetPluginPlugin>('ImportJourneySetPlugin', {
  web: () => import('./web').then(m => new m.ImportJourneySetPluginWeb()),
});

export interface StorageLocationPickerPlugin {
  save(options: { filename: string; content: string; plainText: boolean; mimeType: string; useDeviceApk: boolean }): Promise<{ uri: string }>;
  delete(options: { uris: string[] }): Promise<void>;
  navigate(options: { mimeType: string }): Promise<{ uri: string }>;
}

const StorageLocationPicker = registerPlugin<StorageLocationPickerPlugin>('StorageLocationPickerPlugin', {
  web: () => import('./web').then(m => new m.StorageLocationPickerPluginWeb()),
});

export {StorageLocationPicker, ShareApp, DeleteApp, ImportJourneySet};