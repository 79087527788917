import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import {
    getJourneySets,
    getQuadrants,
    getJourneys,
    getQuadrant,
    getJourneySet,
    getRecentJourneys,
    addRecentJourney, 
    Preferences
} from "@/storage/DataStorage";
import {Device} from '@capacitor/device';

/**
 * Common data between UI pages
 */
interface State {
    quadrantId: string;
    journeySetId: string;
    journeyId: string;
    headerImage: string;
    headerTitle: string;
    prevPage: string;
    shouldShowCalculatorIcon: boolean;
    shouldPauseAudio: boolean;
    locale: string;
    messages: any;
    shouldExitApp: boolean;
    loadingValue: number;
    isInvalidUrl: boolean;
    preferences: Preferences;
    failedAttempts: number;
    lockoutDateTime: Date | null;
    needToSetPin: boolean;
    minRequiredLoadData: any;
    isFirstTimeLoadingApp: boolean;
    checkForVersion: boolean;
    platform: string;
    backgroundJourneySets: number;
    latestBackgroundId: string;
    isSecure: boolean;
    downloadDomain: string;
    //Root Language file aka application instance
    variant: string;
    appVersion: number;
    versionUpdated: boolean;
    secureUpdateOverride: boolean;
    isRelease: boolean;
}

export const store = createStore({
    plugins: [createPersistedState({
        paths: [
            'failedAttempts', 'lockoutDateTime', 'isFirstTimeLoadingApp', 'isSecure', 'downloadDomain', 'variant','appVersion'
        ]
    })],
    state(): State {
        return {
            quadrantId: "",
            journeySetId: "",
            journeyId: "",
            headerImage: "",
            headerTitle: "",
            prevPage: "fields",
            shouldShowCalculatorIcon: true,
            shouldPauseAudio: false,
            locale: "",
            messages: {},
            shouldExitApp: true,
            loadingValue: 0,
            isInvalidUrl: false,
            preferences: {} as any,
            failedAttempts: 0,
            lockoutDateTime: null,
            needToSetPin: false,
            minRequiredLoadData: {},
            isFirstTimeLoadingApp: true,
            checkForVersion: false,
            platform: '',
            backgroundJourneySets: 0,
            latestBackgroundId: '',
            isSecure: true,
            downloadDomain: '',
            //Root Language file aka application instance
            variant: '',
            appVersion: 0,
            versionUpdated: false,
            secureUpdateOverride: false,
            isRelease: true
        };
    },

    actions: {
        setQuadrantId({ commit }, quadrantId: string) {
            commit("setQuadrantId", quadrantId);
        },
        setJourneySetId({ commit }, journeySetId: string) {
            commit("setJourneySetId", journeySetId);
        },
        setJourneyId({ commit }, journeyId: string) {
            commit("setJourneyId", journeyId);
        },
        setHeaderImage({ commit }, headerImage: string) {
            commit("setHeaderImage", headerImage);
        },
        setHeaderTitle({ commit }, headerTitle: string) {
            commit("setHeaderTitle", headerTitle);
        },
        setPrevPage({ commit }, routeName: string) {
            commit("setPrevPage", routeName);
        },
        setShouldShowCalculatorIcon({ commit }, shouldShow: boolean) {
            commit("setShouldShowCalculatorIcon", shouldShow);
        },
        addRecentJourney({commit}, journeyId: string) {
            commit("addRecentJourney", journeyId);
        },
        setShouldExitApp({ commit }, shouldExitApp: boolean) {
            commit("setShouldExitApp", shouldExitApp);
        },         
        setShouldPauseAudio({ commit }, shouldPauseAudio: boolean) {
            commit("setShouldPauseAudio", shouldPauseAudio);
        },
        setLocale({ commit }, locale: string) {
            commit("setLocale", locale);
        },
        setMessages({ commit }, messages: any) {
            commit("setMessages", messages);
        },
        setMinRequiredLoadData({ commit }, messages: any) {
            commit("setMinRequiredLoadData", messages);
        },
        setLoadingValue({ commit }, loadingValue: number) {
            commit("setLoadingValue", loadingValue);
        },
        setIsInvalidUrl({ commit }, invalid: boolean) {
            commit("setIsInvalidUrl", invalid);
        },
        setPreferences({ commit }, preferences: Preferences) {
            commit("setPreferences", preferences);
        },
        incrementFailedAttempts({ commit }) {
            commit("incrementFailedAttempts");
        },
        setLockoutDateTime({ commit }) {
            commit("setLockoutDateTime");
        },
        clearLockoutDateTime({ commit }) {
            commit("clearLockoutDateTime");
        },
        clearFailedAttempts({commit}) {
            commit("clearFailedAttempts");
        },
        setNeedToSetPin({ commit }, needToSetPin: boolean) {
            commit("setNeedToSetPin", needToSetPin);
        },
        setIsFirstTimeOpeningApp({ commit }, firstTime: boolean) {
            commit("setIsFirstTimeOpeningApp", firstTime);
        },
        setCheckForVersion({ commit }, checkForVersion: boolean) {
            commit("setCheckForVersion", checkForVersion);
        },
        setPlatform({ commit }) {
            commit("setPlatform");
        },
        incrementBackgroundJourneySets({ commit }, id: any) {
            commit("incrementBackgroundJourneySets", id);
        },
        setAppVersion({commit}, version: number) {
            commit("setAppVersion",version);
        },
        setVersionUpdated({commit}, versionUpdated: boolean){
            commit("setVersionUpdated" , versionUpdated);
        },
        setSecureUpdateOverride({commit}, secureUpdateOverride: boolean){
            commit("setSecureUpdateOverride" , secureUpdateOverride);
        }
    },

    mutations: {
        setQuadrantId(state: any, quadrantId: string) {
            state.quadrantId = quadrantId;
        },
        setJourneySetId(state: any, journeySetId: string) {
            state.journeySetId = journeySetId;
        },
        setJourneyId(state: any, journeyId: string) {
            state.journeyId = journeyId;
        },
        setHeaderImage(state: any, headerImage: string) {
            state.headerImage = headerImage;
        },
        setHeaderTitle(state: any, headerTitle: string) {
            state.headerTitle = headerTitle;
        },
        setPrevPage(state: any, routeName: string) {
            state.prevPage = routeName;
        },
        setShouldShowCalculatorIcon(state: any, shouldShow: boolean) {
           state.shouldShowCalculatorIcon = shouldShow;
        },
        setShouldPauseAudio(state: any, shouldPauseAudio: boolean) {
            state.shouldPauseAudio = shouldPauseAudio;
        },
        setShouldExitApp(state: any, shouldExitApp: boolean) {
            if(state.isSecure){
                state.shouldExitApp = shouldExitApp;
            }else{
                state.shouldExitApp = false;
            }
            
        },
        async addRecentJourney(state: any, journeyId: string) {
            await addRecentJourney(journeyId);
        },
        setLocale(state: any, locale: string) {
            state.locale = locale;
        },
        setMessages(state: any, messages: any) {
            state.messages = messages;
        },
        /**
         * Minimum data for functional application
         */
        setMinRequiredLoadData(state: any, data: {locale: any; quadrants: any;}) {
            state.minRequiredLoadData = data;
            const { locale } = data as any;
            state.locale = Object.keys(locale)[0];
            state.messages = locale[state.locale];
            state.isSecure = state.messages.buildConfigs.config.isSecure === 'true';
            state.simpleCategoryShapes = state.messages.buildConfigs.config.simpleCategoryShapes === 'true';
            state.downloadDomain = state.messages.application.downloadDomain;
            state.variant = state.messages.language.variant;
            state.isRelease = state.messages.language.isRelease === 'true';
        },
        setAppVersion(state: any, version: number) {
            if(version > state.appVersion) {
                state.appVersion = version;
            }
        },
        setLoadingValue(state: any, loadingValue: number) {
            if (loadingValue > state.loadingValue) {
                state.loadingValue = loadingValue;
            }
        },
        setIsInvalidUrl(state: any, invalid: boolean) {
            state.isInvalidUrl = invalid;
        },
        setPreferences(state: any, preferences: Preferences) {
            state.preferences = preferences;
        },
        incrementFailedAttempts(state: any) {
            state.failedAttempts = state.failedAttempts === 20 ? state.failedAttempts : state.failedAttempts + 1;
        },
        setLockoutDateTime(state: any) {
            state.lockoutDateTime = new Date();
        },
        clearLockoutDateTime(state: any) {
            state.lockoutDateTime = null;
        },
        clearFailedAttempts(state: any) {
            state.failedAttempts = 0;
        },
        setNeedToSetPin(state: any, needToSetPin: boolean) {
            state.needToSetPin = needToSetPin;
        },
        setIsFirstTimeOpeningApp(state: any, isFirstTime: boolean) {
            state.isFirstTimeLoadingApp = isFirstTime;
        },
        setCheckForVersion(state: any, checkForVersion: boolean) {
            state.checkForVersion = checkForVersion;
        },
        /**
         * Device.getInfo() is an expensive call, stashing the result increases computational performance
         * @param state
         */
        async setPlatform(state: any) {
            const deviceInfo = await Device.getInfo();
            state.platform = deviceInfo.platform;
        },
        /**
         * Used by watchers to refresh the journey set page on success of background processing 
         * @param state current state
         * @param id id that completed processing, only reload if this id is on the current page to prevent flickering 
         * unnecessarily 
         */
        incrementBackgroundJourneySets(state: any, id: string) {
            state.latestBackgroundId = id;
            state.backgroundJourneySets++;
        },
        setVersionUpdated(state: any , value: boolean){
            state.versionUpdated = value;
        },
        setSecureUpdateOverride(state: any , value: boolean){
            state.secureUpdateOverride = value;
        }
    },

    getters: {
        getQuadrants: () => (subType: string): any => {
            return getQuadrants(subType);
        },
        getQuadrant: () => (quadrantId: string): Promise<any> => {
            return getQuadrant(quadrantId);
        },
        getJourneySets: () => (quadrantIdFilter: string): Promise<any> => {
            return getJourneySets(false, quadrantIdFilter);
        },
        getJourneySet: () => (journeySetId: string): Promise<any> => {
            return getJourneySet(journeySetId);
        },
        getJourneys: () => (journeySetIdFilter: string): Promise<any> => {
            return getJourneys(false, journeySetIdFilter);
        },
        getPrevPage: (state: State) => (): any => {
            return state.prevPage;
        },
        getRecentJourneys: () => async (): Promise<any> => {
            return await getRecentJourneys();
        },
        getLocale: (state: State) => (): string => {
            return state.locale;
        },
        getLocaleMessages: (state: State) => (): any => {
            return state.messages;
        },
        getMinRequiredLoadData: (state: State) => (): any => {
            return state.minRequiredLoadData;
        },
        getShouldExitApp: (state: State) => (): boolean => {
            if(state.isSecure){ 
                return state.shouldExitApp;
            }else{
                return false;
            }
        },
        getLoadingValue: (state: State) => (): number => {
            return state.loadingValue;
        },
        getIsInvalidUrl: (state: State) => (): boolean => {
            return state.isInvalidUrl;
        },
        getPreferences: (state: State) => (): Preferences => {
            if (Object.keys(state.preferences).length === 0) {
                return {
                    shouldUseSafetyCalculator: false,
                    shouldDisplayQuickExit: true,
                    shouldDisplayUnencryptedAlert: true,
                    shouldDisplayInternetAlert: true,
                    shouldDisplayAppSharedAlert: true,
                    entrancePin: "1234",
                    lastVisitedRoute: "/fields",
                    deletePin: "6666",
                    canAccessApp: true,
                    shouldSetPin: true,
                    isRelease: false,
                    simpleCategoryShapes: false
                } as Preferences
            }
            return state.preferences;
        },
        getFailedAttempts: (state: State) => (): number => {
            return state.failedAttempts;
        },
        getLockoutDateTime: (state: State) => (): Date | null => {
            return state.lockoutDateTime ? new Date(state.lockoutDateTime) : null;
        },
        getNeedToSetPin: (state: State) => (): boolean => {
            return state.needToSetPin;
        },
        isFirstTimeOpeningApp: (state: State) => (): boolean => {
            return state.isFirstTimeLoadingApp;
        },
        showAllQuadrants: () => (): boolean => {
            let config: any;
            if (store.getters.isFirstTimeOpeningApp()) {
                const { locale } = store.getters.getMinRequiredLoadData();
                config = locale[Object.keys(locale)[0]].buildConfigs.config;
            } else {
                const messages = store.getters.getLocaleMessages();
                config = messages.buildConfigs.config;
            }
            return config.showAllQuadrants === 'true';
        },
        quadrantsToShow: () => (): string[] => {
            return store.getters.showAllQuadrants() ? ['jf1', 'jf2', 'jf3', 'jf4', 'jf5', 'jf6'] : ['jf1', 'jf2', 'jf3', 'jf4'];
        },
        checkForVersion: (state: State) => (): boolean => {
            return state.checkForVersion;
        },
        platform: (state: State) => (): string => {
            return state.platform;
        },
        isPwa: () => (): boolean => {
            return store.getters.platform() === 'web';
        },
        isOnlineApp: () => (): boolean => {
            return !store.getters.isSecure() && !store.getters.isPwa();
        },
        //Is Secure checks PWA, store.state.isSecure just pulls language file setting. This method should eventually 
        // incorporate store.state.isSecure and remove the language file dependency
        isSecure: () => (): boolean => {
            let config: any;
            if (store.getters.isPwa()) {
                return false;
            }

            if (store.getters.isFirstTimeOpeningApp()) {
                const { locale } = store.getters.getMinRequiredLoadData();
                config = locale[Object.keys(locale)[0]].buildConfigs.config;
            } else {
                const messages = store.getters.getLocaleMessages();
                config = messages.buildConfigs.config;
            }
            
            return config.isSecure === 'true';
        },
        getBackgroundProgress: (state: State) => (): number => {
            return state.backgroundJourneySets;
        },
        getAppVersion: (state: State) => (): number => {
            return state.appVersion;
        },
        versionUpdated: (state: State) => (): boolean => {
            return state.versionUpdated;
        },
        secureUpdateOverride: (state: State) => (): boolean => {
            return state.secureUpdateOverride;
        },
    },
});
