import {store} from "@/store";
import {createLoadSpinner, readAndSaveJourneySets} from "@/services/ShareService";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import router from "@/router";
import {Encoding, Filesystem} from '@capacitor/filesystem';
import {ImportJourneySet} from '@/delete-app-plugin/definitions';

export const UNIVERSAL_LANGUAGE_CODE = "000";

export async function checkForJourneySetImport() {
    if (!store.getters.isPwa()) {
        const {uri} = await ImportJourneySet.getJourneySetToImport();
        if (uri) {
            await autoImportJourneySet(uri);
            await ImportJourneySet.resetUri();
        }
    }
}

export function isValidJourneySetFilename(filename: string) {
    return filename.includes("$D3@");
}

export function getJourneySetImportErrors(journeySet: any) {
    const locale = store.getters.getLocale();
    const language = store.getters.getLocaleMessages();
    try {
        if (!Object.prototype.hasOwnProperty.call(journeySet, 'js89sd8lsDKf3') || journeySet.js89sd8lsDKf3 !== "$D3@") {
            return language.application.importInvalidFile;
        } else if (!Object.prototype.hasOwnProperty.call(journeySet, 'locale')) {
            return language.application.importOldJourneySet;
        } else if (journeySet.locale !== locale && journeySet.locale !== UNIVERSAL_LANGUAGE_CODE) {
            return language.application.importWrongLanguage;
        }
        return '';
    } catch {
        return '';
    }
}

async function autoImportJourneySet(uri: string){
    let error = '';
    const loading = await createLoadSpinner();
    const language = store.getters.getLocaleMessages();
    const { filename } = await ImportJourneySet.getFilename();
    if (isValidJourneySetFilename(filename)) {
        try {
            await loading.present();
            const {data} = await Filesystem.readFile({
                path: uri,
                encoding: Encoding.UTF8
            });
            const journeySet = JSON.parse(data.toString());
            const importErrors = getJourneySetImportErrors(journeySet);
            if (importErrors === '') {
                const [quadrantId, journeySetId] = await readAndSaveJourneySets(journeySet, true);
                await loading.dismiss();
                await router.push({
                    name: 'journeys',
                    params: { fieldId: quadrantId, journeySetId: journeySetId }
                });
                return;
            }
            error = importErrors.replace('[%filename]', filename);
        } catch (e) {
            error = language.application.importErrorProcessing.replace('[%filename]', filename);
        }
    } else {
        error = language.application.importInvalidFile.replace('[%filename]', filename);
    }
    console.error(error);
    await loading.dismiss();
    const alert = new AlertBuilder('custom-alert', error).addCancelButton();
    await alert.present();
}

export function videoParse(toParse: string, identifier: string, emptyIfNotFound: boolean): string {
    const index = toParse.indexOf(identifier);
    if(index >= 0) {
        return toParse.substring(index + identifier.length);
    }
    
    return emptyIfNotFound ? '' : toParse;
}

