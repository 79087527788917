import {store} from "@/store";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {Network} from '@capacitor/network';

/**
 * Capacitor uses native hardware lookup, should never talk to the internet unless something changes
 * @param showAlert should an alert show if internet not found?
 */
export const isConnectedToInternet = async (showAlert = true) => {
    const messages = store.getters.getLocaleMessages();
    const { connected } = await Network.getStatus();
    if (connected) {
        return true;
    } else {
        if(showAlert) {
            const alert = new AlertBuilder('custom-alert', messages.application.noInternet).addCancelButton();
            await alert.present();
        }
        return false;
    }
}