<template>
    <ion-app
        :dir="direction"
        data-html2canvas-ignore
        @ionModalWillPresent="pauseAudio"
        @ionModalWillDismiss="pauseAudio"
        @ionActionSheetWillPresent="pauseAudio"
        @ionWillOpen="pauseAudio"
    >
        <main-menu v-if="currentPath !== '/calculator'" :imgSrc="imageForHeader" :pageTitle="textForHeader" />
        <ion-router-outlet/>
    </ion-app>
</template>

<script lang="ts">
import {actionSheetController, menuController, IonApp, IonRouterOutlet, useBackButton} from "@ionic/vue";
import { defineComponent, ref, watch, computed, onMounted, onBeforeMount } from "vue";
import {useRoute, useRouter} from "vue-router";
import { useStore } from "vuex";
import translate from "./views/translate";
import MainMenu from "@/components/MainMenu.vue";
import {savePreferences} from './storage/DataStorage';
import {openOnboardingPagesModal} from "@/services/InformationPagesService";
import {checkForJourneySetImport} from "@/utilities/JourneySet";
import {getImageDataUri} from "@/utilities/Compatibility";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {handleAndroidFileSelectToDelete} from "@/utilities/Filesystem";
import {MimeTypes} from "@/types/types";
import {App as capacitorApp} from '@capacitor/app';

export default defineComponent({
    name: "App",
    components: {
        IonRouterOutlet,
        MainMenu,
        IonApp,
    },
    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const { t } = translate();
        
        const currentPath = ref(route.path);
        const currentPathName = ref(route.name);
        const imageForHeader = computed(() => store.state.headerImage);
        const direction = computed(() => t("messages.language.textDirection"));
        const textForHeader = computed(() => store.state.headerTitle);
        
        function initColors() {
            window.document.body.style.setProperty('--color-primary', `rgb(${t("messages.application.primaryColor")})`);
            window.document.body.style.setProperty('--color-primary-rgb', t("messages.application.primaryColor"));
            window.document.body.style.setProperty('--quadrant-field-1-background', t("messages.application.quadrantColors.quadrant1"));
            window.document.body.style.setProperty('--quadrant-field-2-background', t("messages.application.quadrantColors.quadrant2"));
            window.document.body.style.setProperty('--quadrant-field-3-background', t("messages.application.quadrantColors.quadrant3"));
            window.document.body.style.setProperty('--quadrant-field-4-background', t("messages.application.quadrantColors.quadrant4"));
            window.document.body.style.setProperty('--quadrant-field-5-background', t("messages.application.quadrantColors.quadrant5"));
            window.document.body.style.setProperty('--quadrant-field-6-background', t("messages.application.quadrantColors.quadrant6"));
        }

        function initSizes() {
            window.document.body.style.setProperty('--quadrant-font-size', t("messages.application.quadrantFontSize"));
            
            const journeyImgSize = t("messages.application.journeyImageSize");
            if (journeyImgSize === "medium") {
                window.document.body.style.setProperty('--journey-header-img-width', '30vh');
            } else if (journeyImgSize === "large") {
                window.document.body.style.setProperty('--journey-header-img-width', '50vh');
            } else {
                window.document.body.style.setProperty('--journey-header-img-width', '20vh');
            }
        }
        
        onBeforeMount(async () => {          
            const { setLocaleMessage, t } = translate();
            const locale = store.getters.getLocale();
            const messages = store.getters.getLocaleMessages();
            
            if (store.getters.isFirstTimeOpeningApp()) {
                await openOnboardingPagesModal(messages);
                await store.dispatch("setIsFirstTimeOpeningApp", false);
                await deleteInstallFile(messages);
            } 
            setLocaleMessage(locale, { messages: messages });
            
            // some header items need to be set here if they happen to be loading the app on these pages. this is due to having the images in the language file 
            if(route.name === 'security') {
                await store.dispatch("setHeaderImage", getImageDataUri(t("messages.securityPage.headerImage")))
            } else if(route.name === 'privacy'){
                await store.dispatch("setHeaderImage", getImageDataUri(t("messages.privacyPage.headerImage")))
            } else if(route.name === 'fields'){
                 await store.dispatch("setHeaderTitle", `${t("messages.application.appName")}`);
            }

            window.document.body.style.setProperty('--center-text-for-language', direction.value === 'ltr' ? 'center' : 'right' );
            initColors();
            initSizes();
        });
        
        onMounted(async () => {
            const preferences = store.getters.getPreferences();
            
            //Once the app is mounted, then store all preferences to the indexedDB
            //that may have been set or modified during initial app load
            await savePreferences(preferences);
            
            if (!preferences.shouldUseSafetyCalculator) {
                store.dispatch("set")
                await checkForJourneySetImport();
            }
        })
        const shouldExitApp = computed(() =>store.getters.getShouldExitApp());
        capacitorApp.addListener("appStateChange", async (state: any) => {
            pauseAudio();
            actionSheetController.dismiss();
            menuController.close();
            if (!state.isActive && shouldExitApp.value === true) {
                const preferences = store.getters.getPreferences();
                if (preferences.shouldUseSafetyCalculator) {
                    router.push({ name: 'calculator'});
                }
                capacitorApp.exitApp();
            } else if(!state.isActive) {
                await store.dispatch("setShouldExitApp", true);
            } else if (state.isActive && store.getters.platform() === "ios") {
                await checkForJourneySetImport();
            }
        });

        watch(route, (currentRoute) => {
            store.dispatch("setPrevPage", currentPathName.value == "calculator" ? "fields" : currentPath.value);
            currentPath.value = currentRoute.path;
            currentPathName.value = currentRoute.name;
        });

        // this is a global handler for capturing android back buttons on the calculator screen
        useBackButton(10, (processNextHandler) => {
            if (!currentPath.value.includes("calculator")) {
                processNextHandler();
            }
        });

        function pauseAudio() {
            store.dispatch("setShouldPauseAudio", true);
        }
        
        async function deleteInstallFile(messages: any) {
            if (store.getters.platform() === "android" && messages.buildConfigs.config.appStoreBuild === "false") {
                const alert = new AlertBuilder('custom-alert', `<p>${ messages.application.deleteInstallFile }</p>`)
                    .addNoButton()
                    .addYesButton(async () => {
                        await handleAndroidFileSelectToDelete(MimeTypes.APK);
                    });
                await alert.present();
            }
        }

        return {
            currentPath,
            imageForHeader,
            textForHeader,
            direction,
            pauseAudio
        };
    },
});
</script>

<style scoped>
ion-app {
    max-width: 1024px;
    margin: auto;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.25);
}

ion-app.ios {
    max-width: 100vw;
}

ion-menu ion-content {
    --background: var(--ion-color-custom-step-0);
}

ion-menu.md ion-content {
    --padding-start: 8px;
    --padding-end: 8px;
    --padding-top: 20px;
    --padding-bottom: 20px;
}

ion-menu.md ion-list {
    padding: 20px 0;
}

ion-menu.md ion-note {
    margin-bottom: 30px;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
    padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
    border-bottom: 1px solid var(--ion-color-custom-step-150, var(--menu-border));
}

ion-menu.md ion-list#inbox-list ion-list-header {
    font-size: 22px;
    font-weight: 600;
    min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
    font-size: 16px;
    margin-bottom: 18px;
    color: var(--menu-header-text);
    min-height: 26px;
}

ion-menu.md ion-item {
    --padding-start: 10px;
    --padding-end: 10px;
    border-radius: 4px;
}

ion-menu.md ion-item.selected {
    --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-label {
    font-weight: 500;
}

ion-menu.ios ion-content {
    --padding-bottom: 20px;
}

ion-menu.ios ion-list {
    padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
    line-height: 24px;
    margin-bottom: 20px;
}

ion-menu.ios ion-item {
    --padding-start: 16px;
    --padding-end: 16px;
    --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
    color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
    font-size: 24px;
}

ion-menu.ios ion-list#labels-list ion-list-header {
    margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
    padding-left: 16px;
    padding-right: 16px;
}

ion-menu.ios ion-note {
    margin-bottom: 8px;
}

ion-note {
    display: inline-block;
    font-size: 16px;

    color: var(--ion-color-medium-shade);
}

ion-item.selected {
    --color: var(--ion-color-primary);
}
</style>
