import { encryptEntry, decryptEntry } from "./SecurityService";
import { v4 as uuidv4 } from "uuid";
import {
    getMediaItem,
    getJourneys,
    getJourneySets,
    getJourney,
    updateJourney,
    getJourneySet,
    deleteMedia,
    updateJourneySet,
    getMedia,
    savePreferences,
    Preferences,
    saveMedia,
    saveJourneySet,
    saveJourney,
    JourneySet,
    Journey
} from "@/storage/DataStorage";
import { alertController, loadingController } from "@ionic/vue";
import { downloadAPK } from './AWSService';
import { store } from "@/store";
import {FileIdentifiers, MimeTypes} from "@/types/types";
import {shareFile, writeFile , checkAndRequestStoragePermission} from "@/utilities/Filesystem";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {connectToInternetAlert} from "@/utilities/Alerts";
import {Device} from '@capacitor/device';
import {ShareApp} from '@/delete-app-plugin/definitions';

export enum ShareType {
    PDF = "PDF",
    APP = "APP",
    Audio = "Audio",
    JourneySet = "JourneySet"
}

export interface JourneySetToSend {
    journeySetToSend: any; 
    journeySetFile: string;
}

export function createLoadSpinner(showBackdrop = true): Promise<HTMLIonLoadingElement> {
    return loadingController.create({backdropDismiss:false, spinner: "crescent", showBackdrop: showBackdrop});
}

async function createJourneySetsToSend(journeySetId = "") {
    const journeySets = await getJourneySets(true);
    const journeys = await getJourneys(true);
    const allMedia = await getMedia(true);
    const journeySetsToSend = journeySetId ? 
        journeySets.filter((set) => set.id === journeySetId) : 
        journeySets.filter((set) => set.isDefaultSet);
    const filteredSetsToSend = journeySetsToSend.map((journeySet) => {
        return {
                js89sd8lsDKf3: '$D3@',
                locale: store.getters.getLocale(),
                name: journeySet.name,
                icon: allMedia.find((media) => {
                    return media.id === journeySet.iconId;
                })?.content,
                id: journeySet.id,
                quadrantId: journeySet.quadrantId,
                pdfName: journeySet.pdfName,
                order: journeySet.order,
                tabContentImages: journeySet.tabContentImages,
                tabImages: journeySet.tabImages,
                numberOfJourneys: journeys
                    .filter((journey) => {
                        return (journey.journeySetId.toLowerCase() === journeySet.id ||
                                journey.journeySetId.toUpperCase() === journeySet.id);
                    }).length || 0,
                isDefaultSet: journeySet.isDefaultSet,
                alreadyDownloaded: true,
                updateAvailable: false,
                deleted: false,
                unavailableToDownload: false,
                journeys: journeys
                    .filter((journey) => {
                        // See dataStorage.getJourneySet 
                        return (journey.journeySetId.toLowerCase() === journeySet.id ||
                                journey.journeySetId.toUpperCase() === journeySet.id);
                    }).map((journey) => {
                        return {
                            order: journey.order,
                            name: journey.name,
                            description: journey.description,
                            id: journey.id,
                            audioFile: allMedia.find((media) => {
                                return media.id === journey.audioFileId;
                            })?.content,
                            videoFile: allMedia.find((media) => {
                                return media.id === journey.videoFileId;
                            })?.content,
                            tabs: journey.tabs.map((tab) => {
                                return {
                                    tabName: tab.tabName,
                                    contentText: tab.contentText,
                                };
                            }),
                        };
                    }),
        };
    });
    const encryptedJourneySets = [] as any[];
    filteredSetsToSend.forEach((journeySet) => {
        const encryptedJourneysAndTabs = [] as any[];
        journeySet.journeys.forEach((journey) => {
            encryptedJourneysAndTabs.push(encryptEntry(journey, journey.tabs, "tabs"));
        });
        const encryptedJourneySet = encryptEntry(journeySet, [], "journeys");
        encryptedJourneySet.journeys = encryptedJourneysAndTabs;
        encryptedJourneySets.push(encryptedJourneySet);
    });
    return encryptedJourneySets[0];
}

async function generateAppToShare() {
    const { platform } = await Device.getInfo();
    const loading = await createLoadSpinner();
    await loading.present();
    if (platform === 'web') {
        await downloadAPK(true);
        await loading.dismiss();
    } else {
        await store.dispatch("setShouldExitApp", false);
        const messages = store.getters.getLocaleMessages();
        const apkName = `${messages.application.displayName}${ FileIdentifiers.Apk }`;
        await loading.dismiss();
        await ShareApp.shareApp({ appName: apkName });
        await store.dispatch("setShouldExitApp", true);
    }
}

async function showShareAppThankYou(preferences: Preferences) {
    const messages = store.getters.getLocaleMessages();
    let shouldContinueShowingAlert = true;
    const alert = await alertController.create({
        cssClass: "custom-alert",
        message: `<p>${messages.application.sharedTheAppMessage}</p>`,
        inputs: [
            {
                type: "checkbox",
                label: messages.application.dontShowAgain,
                checked: false,
                cssClass: "do-not-show-alert-checkbox",
                handler: async () => {
                    shouldContinueShowingAlert = !shouldContinueShowingAlert;
                },
            },
        ],
        buttons: [
            {
                text: messages.application.close,
                cssClass: "alert-button-yes",
                role: "cancel",
                handler: async () => {
                    preferences.shouldDisplayAppSharedAlert = shouldContinueShowingAlert;
                    await savePreferences(preferences);
                },
            },
        ],
    });
    await alert.present();
    await alert.onDidDismiss().then(() => {
        generateAppToShare();
    });
}

export async function shareApp(): Promise<void> {
    try {
        const preferences = store.getters.getPreferences();
        if (preferences.shouldDisplayAppSharedAlert) {
            showShareAppThankYou(preferences);
        } else {
            generateAppToShare();
        }
    } catch {
        return;
    }
}

export async function saveApp(): Promise<void> {
    const { platform } = await Device.getInfo();
    if (platform === 'web') {
        const loading = await createLoadSpinner();
        await loading.present();
        await downloadAPK(true);
        await loading.dismiss();
    } else {
        const messages = store.getters.getLocaleMessages();
        const apkName = `${messages.application.displayName}${FileIdentifiers.Apk}`;
        await writeFile(`${apkName}.apk`, '', true, MimeTypes.APK, true);
    }
}

async function getAudio(id: string) {
    const loading = await createLoadSpinner();
    await loading.present();
    const audioFile = await getMediaItem(id);
    await loading.dismiss();
    return audioFile.content;
}

export async function shareAudio(audioFileId: string, fileName: string): Promise<void> {
    const content = await getAudio(audioFileId);
    await shareFile(`${fileName}_${ FileIdentifiers.Audio }.mp3`, content, false, 'audio/mpeg');
}

export async function saveAudioToDevice(audioFileId: string, fileName: string): Promise<void> {
    const content = await getAudio(audioFileId);
    await writeFile(`${fileName}_${ FileIdentifiers.Audio }.mp3`, content, false, MimeTypes.AUDIO, false);
}



async function getJourneySetData(id: string): Promise<JourneySetToSend> {
    const loading = await createLoadSpinner();
    await loading.present();
    const journeySetToSend = await createJourneySetsToSend(id);
    const journeySet = await getJourneySet(id);
    const journeySetFile = `${journeySet.pdfName}-${ FileIdentifiers.JourneySet }${id}.txt`;
    await loading.dismiss();
    return { journeySetToSend: journeySetToSend, journeySetFile : journeySetFile };
}

export async function shareJourneySet(journeySetId: string): Promise<void> {
    const payload = await getJourneySetData(journeySetId);
    await shareFile(payload.journeySetFile, JSON.stringify(payload.journeySetToSend), true, 'text/plain');
}

export async function saveJourneySetToDevice(journeySetId: string): Promise<void> {
    const { journeySetToSend, journeySetFile } = await getJourneySetData(journeySetId);
    await writeFile(journeySetFile, JSON.stringify(journeySetToSend), true, MimeTypes.TEXT, false);
}

export async function readAndSaveJourneySets(journeySet: any, shouldDecrypt = true): Promise<any[]> {
    const mediaToCleanup = [] as string[];
    const decryptedJourneysAndTabs = [] as any[];
    journeySet.journeys.forEach((journey: Journey) => {
        if (shouldDecrypt) {
            decryptedJourneysAndTabs.push(decryptEntry(journey, journey.tabs, "tabs"));
        } else {
            decryptedJourneysAndTabs.push(journey);
        }
    });
    const decryptedJourneySet = shouldDecrypt ? (decryptEntry(journeySet, [], "journeys") as JourneySet) : journeySet;
    decryptedJourneySet.journeys = decryptedJourneysAndTabs;
    let journeySetIconId = "";
    const journeySetInDB = await getJourneySet(decryptedJourneySet.id);
    if (decryptedJourneySet.icon) {
        journeySetIconId = uuidv4();
        await saveMedia({ id: journeySetIconId, content: decryptedJourneySet.icon });

        if (journeySetInDB) {
            mediaToCleanup.push(journeySetInDB.iconId);
        }
    }

    const journeySetToSaveOrUpdate = {
        id: decryptedJourneySet.id,
        pdfName: decryptedJourneySet.pdfName,
        name: decryptedJourneySet.name,
        iconId: journeySetIconId,
        quadrantId: decryptedJourneySet.quadrantId,
        isDefaultSet: decryptedJourneySet.isDefaultSet,
        tabContentImages: decryptedJourneySet.tabContentImages,
        tabImages: decryptedJourneySet.tabImages,
        order: decryptedJourneySet.order,
        numberOfJourneys: decryptedJourneySet.journeys?.length || 0,
        locale: decryptedJourneySet.locale ?? store.getters.getLocale(),
        alreadyDownloaded: true,
        updateAvailable: false,
        deleted: false,
        unavailableToDownload: false
    };
    if (journeySetInDB) {
        // See Data Storage.getJourneySet
        journeySetToSaveOrUpdate.id = journeySetInDB.id;
        await updateJourneySet(journeySetToSaveOrUpdate);
    } else {
        await saveJourneySet(journeySetToSaveOrUpdate);
    }
    await Promise.all(
        decryptedJourneySet.journeys.map(async (j: Journey) => {
            const { tabs = [] } = j as any;
            const journeyInDB = await getJourney(j.id);

            let journeyAudioId = "";
            if (j.audioFile) {
                journeyAudioId = uuidv4();
                await saveMedia({ id: journeyAudioId, content: j.audioFile });

                if (journeyInDB && journeyInDB.audioFileId) {
                    mediaToCleanup.push(journeyInDB.audioFileId);
                }
            }

            const journeyToSaveOrUpdate = {
                id: j.id,
                name: j.name,
                description: j.description,
                audioFileId: journeyAudioId,
                journeySetId: decryptedJourneySet.id,
                iconId: journeySetIconId,
                isComplete: journeyInDB ? journeyInDB.isComplete : false,
                order: j.order,
                tabs,
            };

            if (journeyInDB) {
                await updateJourney(journeyToSaveOrUpdate);
            } else {
                await saveJourney(journeyToSaveOrUpdate);
            }
        })
    );
    for (const mediaId of mediaToCleanup) {
        await deleteMedia(mediaId);
    }
    
    return [journeySetToSaveOrUpdate.quadrantId, journeySetToSaveOrUpdate.id];
}

export async function showSuccessfulExportAlert(filePath: string): Promise<void> {
    const language = store.getters.getLocaleMessages();
    const alert = new AlertBuilder('custom-alert custom-url-alert',
        `<p>${language.application.successfulExport}</p><p class="no-break">${language.application.exportDescriptor}/${filePath}</p>`
    ).addCancelButton();
    await alert.present();
}

export async function share(shareAction: () => any, unEncrypted = false): Promise<void> {
    try {
        const shouldDisplayInternetAlert = await connectToInternetAlert(async () => {
            shareAction();
        }, unEncrypted);
        if (!shouldDisplayInternetAlert) {
            shareAction();
        }
    } catch {
        return;
    }
}