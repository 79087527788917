import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "nav-menu" }
const _hoisted_2 = {
  key: 0,
  class: "title-container image-and-text-title-container"
}
const _hoisted_3 = {
  key: 1,
  class: "title-container"
}
const _hoisted_4 = { class: "menu-image" }
const _hoisted_5 = { class: "menu-text" }
const _hoisted_6 = { class: "menu-item" }
const _hoisted_7 = { class: "menu-image" }
const _hoisted_8 = { class: "menu-text" }
const _hoisted_9 = { class: "menu-item" }
const _hoisted_10 = { class: "menu-image" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "menu-text" }
const _hoisted_13 = { class: "menu-image" }
const _hoisted_14 = { class: "menu-text" }
const _hoisted_15 = { class: "menu-extras" }
const _hoisted_16 = { class: "sub-menu-item" }
const _hoisted_17 = { class: "menu-image" }
const _hoisted_18 = ["src"]
const _hoisted_19 = { class: "menu-text" }
const _hoisted_20 = { class: "menu-image" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "menu-text" }
const _hoisted_23 = { class: "menu-image" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "menu-text" }
const _hoisted_26 = { for: "encryptedFile" }
const _hoisted_27 = { class: "menu-item" }
const _hoisted_28 = { class: "menu-image" }
const _hoisted_29 = { class: "menu-text" }
const _hoisted_30 = { for: "unencryptedFile" }
const _hoisted_31 = { class: "menu-item" }
const _hoisted_32 = { class: "menu-image" }
const _hoisted_33 = { class: "menu-text" }
const _hoisted_34 = { class: "menu-image" }
const _hoisted_35 = { class: "menu-text" }
const _hoisted_36 = { class: "menu-extras" }
const _hoisted_37 = { class: "sub-menu-item" }
const _hoisted_38 = { class: "menu-image" }
const _hoisted_39 = { class: "menu-text" }
const _hoisted_40 = { class: "sub-menu-item" }
const _hoisted_41 = { class: "menu-image" }
const _hoisted_42 = { class: "menu-text" }
const _hoisted_43 = { class: "sub-menu-item" }
const _hoisted_44 = { class: "menu-image" }
const _hoisted_45 = { class: "menu-text" }
const _hoisted_46 = { class: "sub-menu-item" }
const _hoisted_47 = { class: "menu-image" }
const _hoisted_48 = { class: "menu-text" }
const _hoisted_49 = { class: "sub-menu-item" }
const _hoisted_50 = { class: "menu-image" }
const _hoisted_51 = { class: "menu-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_menu_button = _resolveComponent("ion-menu-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_menu_toggle = _resolveComponent("ion-menu-toggle")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, {
      class: "menu-header",
      mode: "md"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, {
          color: "app-primary",
          mode: "md"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_ion_icon, {
                onClick: _ctx.returnHome,
                icon: _ctx.homeSharp,
                class: "home-image"
              }, null, 8, ["onClick", "icon"]),
              _createVNode(_component_ion_title, { class: "nav-title" }, {
                default: _withCtx(() => [
                  (_ctx.pageTitle.length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        _createVNode(_component_ion_icon, {
                          src: _ctx.imgSrc,
                          class: "title-image"
                        }, null, 8, ["src"]),
                        _createElementVNode("span", null, _toDisplayString(_ctx.pageTitle), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_ctx.imgSrc)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              src: _ctx.imgSrc,
                              class: "title-image"
                            }, null, 8, ["src"]))
                          : _createCommentVNode("", true)
                      ]))
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "end" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_menu_button, { "auto-hide": "false" }, {
                    default: _withCtx(() => [
                      (_ctx.isMenuOpen)
                        ? (_openBlock(), _createBlock(_component_ion_icon, {
                            key: 0,
                            class: "close-icon",
                            icon: _ctx.closeSharp
                          }, null, 8, ["icon"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_menu, {
      side: _ctx.dynamicSide,
      "swipe-gesture": "false",
      "content-id": "main-content",
      class: "custom-menu",
      onIonWillOpen: _cache[11] || (_cache[11] = ($event: any) => (_ctx.toggleMenu(true))),
      onIonWillClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.toggleMenu(false))),
      onIonDidClose: _ctx.closeSubMenus
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ion_content, { class: "menu-content" }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list, { class: "menu-list" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_menu_toggle, { "auto-hide": "true" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { class: "menu-row ion-no-padding" }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "menu-item",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleShareApp && _ctx.handleShareApp(...args)))
                        }, [
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_ion_icon, {
                              class: "icon",
                              icon: _ctx.shareSocial
                            }, null, 8, ["icon"])
                          ]),
                          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.shareAppText), 1),
                          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "menu-extras" }, null, -1))
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.t('messages.menu.findCoach') !== 'messages.menu.findCoach')
                  ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      key: 0,
                      "auto-hide": "true"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          class: "menu-row ion-no-padding",
                          onClick: _ctx.goToCoach
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_6, [
                              _createElementVNode("div", _hoisted_7, [
                                _createVNode(_component_ion_icon, {
                                  src: "assets/img/person.svg",
                                  class: "icon"
                                })
                              ]),
                              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.t("messages.menu.findCoach")), 1),
                              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "menu-extras" }, null, -1))
                            ])
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showBigPicture && _ctx.showAllQuadrants)
                  ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      key: 1,
                      "auto-hide": "true"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, {
                          class: "menu-row ion-no-padding",
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openBigPicturePagesModal(_ctx.localeMessages)))
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_9, [
                              _createElementVNode("div", _hoisted_10, [
                                _createElementVNode("img", { src: _ctx.bigPictureIcon }, null, 8, _hoisted_11)
                              ]),
                              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.t("messages.menu.bigPicture")), 1)
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showFindMore && !_ctx.showAllQuadrants)
                  ? (_openBlock(), _createBlock(_component_ion_item, {
                      key: 2,
                      class: "menu-row ion-no-padding"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", {
                          class: "menu-item",
                          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.toggleFindMore && _ctx.toggleFindMore(...args)))
                        }, [
                          _createElementVNode("div", _hoisted_13, [
                            _createVNode(_component_ion_icon, {
                              src: "assets/img/binoculars.svg",
                              class: "icon"
                            })
                          ]),
                          _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.t("messages.menu.findMore")), 1),
                          _createElementVNode("div", _hoisted_15, [
                            (_ctx.expandFindMore)
                              ? (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 0,
                                  icon: _ctx.chevronUp
                                }, null, 8, ["icon"]))
                              : (_openBlock(), _createBlock(_component_ion_icon, {
                                  key: 1,
                                  icon: _ctx.chevronDown
                                }, null, 8, ["icon"]))
                          ])
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.expandFindMore)
                  ? (_openBlock(), _createBlock(_component_ion_list, {
                      key: 3,
                      class: "menu-list"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.showBigPicture)
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 0,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: "sub-menu-row ion-no-padding",
                                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openBigPicturePagesModal(_ctx.localeMessages)))
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_16, [
                                      _createElementVNode("div", _hoisted_17, [
                                        _createElementVNode("img", { src: _ctx.bigPictureIcon }, null, 8, _hoisted_18)
                                      ]),
                                      _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.t("messages.menu.bigPicture")), 1)
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.showMoreTopics)
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 1,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, { class: "sub-menu-row ion-no-padding" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "sub-menu-item",
                                      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.goToMoreTopics && _ctx.goToMoreTopics(...args)))
                                    }, [
                                      _createElementVNode("div", _hoisted_20, [
                                        _createElementVNode("img", { src: _ctx.moreTopicsIcon }, null, 8, _hoisted_21)
                                      ]),
                                      _createElementVNode("p", _hoisted_22, _toDisplayString(_ctx.t("messages.menu.moreTopics")), 1)
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.showLearnMore)
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 2,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, { class: "sub-menu-row ion-no-padding" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "sub-menu-item",
                                      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.goToLearnMore && _ctx.goToLearnMore(...args)))
                                    }, [
                                      _createElementVNode("div", _hoisted_23, [
                                        _createElementVNode("img", { src: _ctx.learnMoreIcon }, null, 8, _hoisted_24)
                                      ]),
                                      _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.t("messages.menu.learnMore")), 1)
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_menu_toggle, { "auto-hide": "true" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_item, { class: "menu-row ion-no-padding" }, {
                      default: _withCtx(() => [
                        _createElementVNode("label", _hoisted_26, [
                          _createElementVNode("div", _hoisted_27, [
                            _createElementVNode("div", _hoisted_28, [
                              _createVNode(_component_ion_icon, {
                                src: "assets/img/systemUpdate.svg",
                                class: "icon"
                              })
                            ]),
                            _createElementVNode("p", _hoisted_29, _toDisplayString(_ctx.t("messages.menu.import")), 1),
                            _cache[15] || (_cache[15] = _createElementVNode("div", { class: "menu-extras" }, null, -1))
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_ctx.showUnencrypted)
                  ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                      key: 4,
                      "auto-hide": "true"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_item, { class: "menu-row ion-no-padding" }, {
                          default: _withCtx(() => [
                            _createElementVNode("label", _hoisted_30, [
                              _createElementVNode("div", _hoisted_31, [
                                _createElementVNode("div", _hoisted_32, [
                                  _createVNode(_component_ion_icon, {
                                    src: "assets/img/systemUpdate.svg",
                                    class: "icon"
                                  })
                                ]),
                                _createElementVNode("p", _hoisted_33, _toDisplayString(_ctx.t("messages.menu.importUnencrypted")), 1),
                                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "menu-extras" }, null, -1))
                              ])
                            ])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_ion_item, { class: "menu-row ion-no-padding" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", {
                      class: "menu-item",
                      onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.toggleSettings && _ctx.toggleSettings(...args)))
                    }, [
                      _createElementVNode("div", _hoisted_34, [
                        _createVNode(_component_ion_icon, {
                          src: "assets/img/settings.svg",
                          class: "icon"
                        })
                      ]),
                      _createElementVNode("p", _hoisted_35, _toDisplayString(_ctx.t("messages.menu.settings")), 1),
                      _createElementVNode("div", _hoisted_36, [
                        (_ctx.showSettings)
                          ? (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 0,
                              icon: _ctx.chevronUp
                            }, null, 8, ["icon"]))
                          : (_openBlock(), _createBlock(_component_ion_icon, {
                              key: 1,
                              icon: _ctx.chevronDown
                            }, null, 8, ["icon"]))
                      ])
                    ])
                  ]),
                  _: 1
                }),
                (_ctx.showSettings)
                  ? (_openBlock(), _createBlock(_component_ion_list, {
                      key: 5,
                      class: "menu-list"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_menu_toggle, { "auto-hide": "true" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              class: "sub-menu-row ion-no-padding",
                              onClick: _ctx.goToSecurity
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_37, [
                                  _createElementVNode("div", _hoisted_38, [
                                    _createVNode(_component_ion_icon, {
                                      src: "assets/img/lock.svg",
                                      class: "icon"
                                    })
                                  ]),
                                  _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.t("messages.menu.security")), 1)
                                ])
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_menu_toggle, { "auto-hide": "true" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              class: "sub-menu-row ion-no-padding",
                              onClick: _ctx.goToPrivacy
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_40, [
                                  _createElementVNode("div", _hoisted_41, [
                                    _createVNode(_component_ion_icon, {
                                      src: "assets/img/privacy.svg",
                                      class: "icon"
                                    })
                                  ]),
                                  _createElementVNode("p", _hoisted_42, _toDisplayString(_ctx.t("messages.menu.privacy")), 1)
                                ])
                              ]),
                              _: 1
                            }, 8, ["onClick"])
                          ]),
                          _: 1
                        }),
                        (_ctx.isOfflineAndroid)
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 0,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: "sub-menu-row ion-no-padding",
                                  onClick: _ctx.checkForUpdatesPreCheck
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_43, [
                                      _createElementVNode("div", _hoisted_44, [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.cloudDownload,
                                          class: "icon"
                                        }, null, 8, ["icon"])
                                      ]),
                                      _createElementVNode("p", _hoisted_45, _toDisplayString(_ctx.t("messages.menu.checkForUpdates")), 1)
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.platform === 'android')
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 1,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: "sub-menu-row ion-no-padding",
                                  onClick: _ctx.deleteAndroidFiles
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_46, [
                                      _createElementVNode("div", _hoisted_47, [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.sparkles,
                                          class: "icon"
                                        }, null, 8, ["icon"])
                                      ]),
                                      _createElementVNode("p", _hoisted_48, _toDisplayString(_ctx.t("messages.menu.cleanFiles")), 1)
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        (_ctx.platform === 'android')
                          ? (_openBlock(), _createBlock(_component_ion_menu_toggle, {
                              key: 2,
                              "auto-hide": "true"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_item, {
                                  class: "sub-menu-row ion-no-padding",
                                  onClick: _ctx.deleteApp
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_49, [
                                      _createElementVNode("div", _hoisted_50, [
                                        _createVNode(_component_ion_icon, {
                                          icon: _ctx.exit,
                                          class: "icon"
                                        }, null, 8, ["icon"])
                                      ]),
                                      _createElementVNode("p", _hoisted_51, _toDisplayString(_ctx.t("messages.menu.uninstall")), 1)
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["onClick"])
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (_ctx.showUnencrypted)
                  ? (_openBlock(), _createElementBlock("input", {
                      key: 6,
                      class: "hidden-input",
                      type: "file",
                      id: "unencryptedFile",
                      accept: "application/json",
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.setShouldExitApp && _ctx.setShouldExitApp(...args))),
                      onChange: _cache[8] || (_cache[8] = ($event: any) => (_ctx.importJourneySets($event, false))),
                      multiple: ""
                    }, null, 32))
                  : _createCommentVNode("", true),
                _createElementVNode("input", {
                  class: "hidden-input",
                  type: "file",
                  id: "encryptedFile",
                  accept: "text/plain,.bin",
                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.setShouldExitApp && _ctx.setShouldExitApp(...args))),
                  onChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.importJourneySets($event, true))),
                  multiple: ""
                }, null, 32)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["side", "onIonDidClose"])
  ], 64))
}