import {store} from "@/store";
import {alertController} from "@ionic/vue";
import {savePreferences} from "@/storage/DataStorage";

export async function connectToInternetAlert(yesHandler: () => any, unEncrypted = false): Promise<boolean> {
    const messages = store.getters.getLocaleMessages();
    const preferences = store.getters.getPreferences();
    if (unEncrypted ? preferences.shouldDisplayUnencryptedAlert: preferences.shouldDisplayInternetAlert) {
        let shouldContinueShowingAlert = true;
        const alert = await alertController.create({
            cssClass: "custom-alert",
            message: unEncrypted ?
                `<p>${messages.application.unencryptedMessage}</p>
                <p>${messages.application.wishToContinue}</p>`: 
                `<p>${messages.application.connectToInternet}</p>
                <p>${messages.application.wishToContinue}</p>`,
            inputs: [
                {
                    type: "checkbox",
                    label: messages.application.dontShowAgain,
                    checked: false,
                    cssClass: "do-not-show-alert-checkbox",
                    handler: async () => {
                        shouldContinueShowingAlert = !shouldContinueShowingAlert;
                    },
                },
            ],
            buttons: [
                {
                    text: messages.application.no,
                    cssClass: "alert-button-no",
                    role: "cancel",
                },
                {
                    text: messages.application.yes,
                    cssClass: "alert-button-yes",
                    handler: async () => {
                        if (!shouldContinueShowingAlert) {
                            if (unEncrypted) {
                                preferences.shouldDisplayUnencryptedAlert = false;
                            } else {
                                preferences.shouldDisplayInternetAlert = false;
                            }
                            await savePreferences(preferences);
                        }

                        yesHandler();
                    },
                },
            ],
        });
        await alert.present();
    }

    return unEncrypted ? preferences.shouldDisplayUnencryptedAlert : preferences.shouldDisplayInternetAlert;
}


export async function setNoLongerAvailableAlert(yesHandler: (journeySetToDelete: string) => any , journeySetToDelete: string) {
    const messages = store.getters.getLocaleMessages();
      
    const alert = await alertController.create({
        cssClass: "custom-alert",
        message: `<p>${messages.application.missingDownloadError}</p>`,
        buttons: [
            {
                text: messages.application.okay,
                cssClass: "alert-button-ok",
                role: "cancel",
                handler: async () => {
                    yesHandler(journeySetToDelete);
                }
            },
        ],
    });
    await alert.present();
    
}