import {alertController} from "@ionic/vue";
import {store} from "@/store";
import router from "@/router";
import {AlertBuilder} from "@/utilities/AlertBuilder";
import {checkForJourneySetImport} from "@/utilities/JourneySet";

export const openAlert = async (
    alertMessage: string,
    alertButtonText: string,
    additionalFunctionToCall: any = undefined
) => {
    const alert = new AlertBuilder('custom-alert',`${alertMessage}`)
        .addCustomButton( async () => {
        await alertController.dismiss();
        if (additionalFunctionToCall) {
            await additionalFunctionToCall();
        }}, `${alertButtonText}`, 'alert-button-yes'
    );
    await alert.present();
};

export const showEmergencyAlertAndNavigate = async (lastVisitedRoute: string, message: string, translate: ((key: string) => string)) => {
    await store.dispatch("setNeedToSetPin", false);
    if (translate("messages.buildConfigs.config.securityDialog") === "true") {
        const alert = new AlertBuilder('emergency-alert',
            `<p>${translate("messages.calculator.emergencyPINMessage")}</p><p>${translate(message)}</p>`,
            `${translate("messages.calculator.emergencyPIN")}`)
            .addCustomButton( async () => {
                    await alertController.dismiss();
                    if (lastVisitedRoute !== "/") {
                        await router.push({path: `${lastVisitedRoute}`});
                    } else {
                        await router.push({name: "fields"});
                    }
                    await checkForJourneySetImport();
                }, `${translate("messages.calculator.next")}`, 'alert-button-yes'
            );
        await alert.present();
    } else {
        if (lastVisitedRoute !== "/") {
            await router.push({path: `${lastVisitedRoute}`});
        } else {
            await router.push({name: "fields"});
        }
        await checkForJourneySetImport();
    }
};