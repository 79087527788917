<template>
    <ion-header translucent>
        <ion-toolbar class="modal-toolbar" mode="md">
            <ion-buttons slot="end">
                <ion-button @click="dismiss">{{ t("messages.application.close") }} <ion-icon :src="close" style="font-size: 20px"/></ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-header>
    <base-layout :shouldShowSubHeader="false" :shouldShowCalculatorIconOnInstance="shouldShowCalculatorIconOnInstance">
        <popup-slides v-if="showSlides" :slideContents="slideContents"/>
        <div v-else />
    </base-layout>
</template>

<script lang="ts">
import { IonHeader, IonToolbar, IonButton, IonButtons, IonIcon } from "@ionic/vue";
import { ref, onMounted, defineComponent } from "vue";
import PopupSlides from "./PopupSlides.vue";
import BaseLayout from "./BaseLayout.vue";
import { close } from "ionicons/icons";
import translate from "../views/translate";
import { SlideContent } from "@/types/types";

export default defineComponent({
    name: "ModalWithSlides",
    components: { PopupSlides, BaseLayout, IonHeader, IonToolbar, IonButton, IonButtons, IonIcon },
    props: {
        dismiss: { type: Function },
        slideContents: { type: Array as () => Array<SlideContent> },
        shouldShowCalculatorIconOnInstance: {type: Boolean, default: true}
    },
    setup() {
        const showSlides = ref(false);
        onMounted(() => {
            // this setTimeout is needed due to a deadlock with loading the slides and modal at the same time :/
            setTimeout(() => {
                showSlides.value = true;
            }, 100);
        });
        const { t } = translate();
        
        return { showSlides, t, close };
    },
});
</script>

<style scoped>
.modal-toolbar {
    --background: var(--color-app-background);
}
</style>
