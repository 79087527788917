import { SlideContent } from "@/types/types";
import { modalController } from "@ionic/vue";
import ModalWithSlides from "@/components/ModalWithSlides.vue";
import {openAlert} from "@/views/Calculator/CalculatorCommon";
import {getVideoDataUri} from "@/utilities/Compatibility";

async function showModalWithSlides(slideContents: SlideContent[]) {
    const modal = await createModalWithSlides(slideContents);
    await modal.present();
}

async function showOnboardingModalWithSlides(slideContents: SlideContent[], dismiss: () => any) {
    const modal = await createOnboardingModalWithSlides(slideContents, dismiss);
    await modal.present();
}

async function createModalWithSlides(slideContents: SlideContent[]) {
    return await modalController.create({
        mode: "md",
        component: ModalWithSlides,
        cssClass: 'custom-modal',
        componentProps: {
            dismiss: async () => await modalController.dismiss(),
            slideContents
        }
    });
}

async function createOnboardingModalWithSlides(slideContents: SlideContent[], dismiss: () => any) {
    const modal = await createModalWithSlides(slideContents);
    modal.componentProps!.dismiss = dismiss;
    modal.componentProps!.shouldShowCalculatorIconOnInstance = false;
    return modal;
}

function getSlideContents(pages: any) {
    return pages.map((page: any) => {
        return {
            imageSrc: getVideoDataUri(page.image),
            contentText: page.contentText,
            audioSrc: page.audioFile
        } as SlideContent;
    });
}

export async function openJourneyHelpPagesModal(localeMessages: any) {
    const slideContents = getSlideContents(localeMessages.journeyHelpPages);
    await showModalWithSlides(slideContents);
}

export async function openBigPicturePagesModal(localeMessages: any) {
    const slideContents = getSlideContents(localeMessages.bigPic);
    await showModalWithSlides(slideContents);
}

export async function openLearnWhereToStartModal(localeMessages: any) {
    const slideContents = getSlideContents(localeMessages.learnWhereToStartPages);
    await showModalWithSlides(slideContents);
}

export async function openOnboardingPagesModal(languageData: any) {
    const dismissModal = async () => {
        const { alternatePinScreen, pinScreenOn } = languageData.buildConfigs.config;
        await modalController.dismiss();
        if (alternatePinScreen === "false" && pinScreenOn === "true") {
            await openAlert(
                `<p>${ languageData.calculator.appSecuredMessage}</p> <p>${ languageData.calculator.createPINMessage }</p>`,
                `${ languageData.calculator.next }`
            );
        }
    };
    const slideContents = getSlideContents(languageData.onboardingPages);
    await showOnboardingModalWithSlides(slideContents, dismissModal);
}