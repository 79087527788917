<template>
    <div class="audio-player" v-if="audioData">
        <div class="near">
            <ion-range 
                ref="seekBar" 
                v-model="seekBarValue" 
                @ionChange="rangeChange" 
                @mousedown="seekBarTouchStart"
                @mouseup="seekBarTouchEnd"
                @touchstart="seekBarTouchStart"
                @touchend="seekBarTouchEnd"
                :style="{'--bar-background-active': 'var('+color+')' }" class="seek-bar" 
                :class="{'enable-transitions': enableTransition }" 
                min="0" 
                max="100" 
                pin="false"></ion-range>
        </div>
        <div class="seek-time">{{audioTime}} / {{endTime}}</div>
        <div class="audio-buttons" :style="{'fill': 'var('+color+')' }" dir="ltr">
            <svg id="rewind-button" @click="seek(-10)" xmlns="http://www.w3.org/2000/svg" width="43.327" height="49.159" viewBox="0 0 43.327 49.159"><defs></defs><g transform="translate(0 -5)"><path class="a" d="M49.327,34A21.664,21.664,0,0,1,6,34h5.416A16.248,16.248,0,1,0,27.664,17.748V23.58l-13.54-8.54L27.664,6.5v5.832A21.727,21.727,0,0,1,49.327,34Z" transform="translate(-6 -1.5)"/><path class="a" d="M2.708,8.031H.446V-2.9l-3.337,1.14V-3.672L2.419-5.625h.29ZM13.916,2.3a7.422,7.422,0,0,1-1.1,4.426A3.879,3.879,0,0,1,9.476,8.218,3.93,3.93,0,0,1,6.153,6.764a7.107,7.107,0,0,1-1.145-4.3V.1a7.31,7.31,0,0,1,1.1-4.4A3.915,3.915,0,0,1,9.457-5.766a3.937,3.937,0,0,1,3.332,1.43A7.087,7.087,0,0,1,13.916-.055ZM11.644-.241a5.758,5.758,0,0,0-.523-2.809,1.786,1.786,0,0,0-1.664-.893A1.785,1.785,0,0,0,7.821-3.1,5.322,5.322,0,0,0,7.27-.456V2.628A5.907,5.907,0,0,0,7.8,5.47,1.79,1.79,0,0,0,9.476,6.4a1.758,1.758,0,0,0,1.617-.865,5.558,5.558,0,0,0,.551-2.706Z" transform="translate(15.665 31.355)"/></g></svg>
            <svg v-if="!isPlaying" @click="togglePlay" id="play-button" xmlns="http://www.w3.org/2000/svg" width="59.052002" height="59.051998" viewBox="0 0 59.052004 59.052" version="1.1"><path class="a" d="M 29.526001,59.052 A 29.526,29.526 0 0 0 59.052001,29.526 29.526,29.526 0 0 0 29.526001,0 29.526,29.526 0 0 0 0,29.526 29.526,29.526 0 0 0 29.526001,59.052 Z m -4.542,-15.9 V 15.9 l 13.627,13.627 z" id="path4" /></svg>
            <svg v-else @click="togglePlay" id="pause-button" xmlns="http://www.w3.org/2000/svg" width="15.62417mm" height="15.62417mm" viewBox="0 0 15.62417 15.62417" version="1.1"> <g transform="translate(-227.82993,-113.89625)"> <path d="M 29.525391 0 C 13.218657 0 2.3684758e-15 13.218657 0 29.525391 C 0 45.832161 13.218619 59.052734 29.525391 59.052734 C 45.832123 59.052734 59.052734 45.832123 59.052734 29.525391 C 59.052734 13.218657 45.832123 2.3684758e-15 29.525391 0 z M 18.927734 16.25 L 26.607422 16.25 L 26.607422 41.875 L 18.927734 41.875 L 18.927734 16.25 z M 32.410156 16.25 L 40.089844 16.25 L 40.089844 41.875 L 32.410156 41.875 L 32.410156 16.25 z " transform="matrix(0.26458333,0,0,0.26458333,227.82993,113.89625)" /></g></svg>
            <svg id="forward-button" @click="seek(10)" xmlns="http://www.w3.org/2000/svg" width="43.327" height="49.159" viewBox="0 0 43.327 49.159"><defs></defs><g transform="translate(0 -5)"><path class="a" d="M6,34a21.664,21.664,0,0,0,43.327,0H43.911A16.248,16.248,0,1,1,27.664,17.748V23.58L41.2,15.04,27.664,6.5v5.832A21.727,21.727,0,0,0,6,34Z" transform="translate(-6 -1.5)"/><path class="a" d="M2.708,8.031H.446V-2.9l-3.337,1.14V-3.672L2.419-5.625h.29ZM13.916,2.3a7.422,7.422,0,0,1-1.1,4.426A3.879,3.879,0,0,1,9.476,8.218,3.93,3.93,0,0,1,6.153,6.764a7.107,7.107,0,0,1-1.145-4.3V.1a7.31,7.31,0,0,1,1.1-4.4A3.915,3.915,0,0,1,9.457-5.766a3.937,3.937,0,0,1,3.332,1.43A7.087,7.087,0,0,1,13.916-.055ZM11.644-.241a5.758,5.758,0,0,0-.523-2.809,1.786,1.786,0,0,0-1.664-.893A1.785,1.785,0,0,0,7.821-3.1,5.322,5.322,0,0,0,7.27-.456V2.628A5.907,5.907,0,0,0,7.8,5.47,1.79,1.79,0,0,0,9.476,6.4a1.758,1.758,0,0,0,1.617-.865,5.558,5.558,0,0,0,.551-2.706Z" transform="translate(15.467 31.357)"/></g></svg>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue';
import { IonRange} from '@ionic/vue';
import { useStore } from 'vuex';
import { getAudioDataUri } from "@/utilities/Compatibility";

export default defineComponent({
    name: 'AudioPlayer',
    props: {
        color: { type: String, default: 'var(--ion-color-primary)' },
        startTimeSeconds: { type: Number, default: 0 },
        endTimeSeconds: { type: Number, default: 0 },
        audioData: { type: String, defaults: '' }
    },
    components: { IonRange  },
    setup(props, { emit }) {
        const store = useStore();
        const isPlaying = ref(false);
        const smartEndTimeSeconds = ref(0);
        const audio = ref(new Audio());
        audio.value.ondurationchange = (e) => {
            // Our calculated endTimeSeconds could be slightly higher than the actual length of the audio file due to small margins
            // of error as part of merging mp3 files. Set the max to be the actual length of the audio file
            smartEndTimeSeconds.value = !props.endTimeSeconds || props.endTimeSeconds > e.target.duration ? e.target.duration : props.endTimeSeconds;
            emit('audioLoaded');
        };

        const audioSrc = computed(() => getAudioDataUri(props.audioData));
        audio.value.src = audioSrc.value;
        audio.value.preload = 'metadata'
        const seekBarValue = ref(0);
        const enableTransition = ref(true);
        const audioTime = ref("0:00");
        let seekbarInterval; 
        let seekbarIsHeld = false;
        
        function pause() {
            isPlaying.value = false;
            clearInterval(seekbarInterval);
            audio.value.pause();
        }

        watch( () => [props.audioData, props.startTimeSeconds, props.endTimeSeconds], () => {
             audio.value.src = getAudioDataUri(props.audioData);
        });
        
        watch(() => store.state.shouldPauseAudio, (newValue) => {
           if (newValue)
               pause();
        });

        function formatSeconds (seconds) {
            seconds = seconds < 0 ? 0 : seconds;
            const minutes = Math.floor(seconds / 60);
            let justSeconds = Math.round(seconds % 60);
            justSeconds = justSeconds < 10 ? "0" + justSeconds : justSeconds;
            return minutes === Infinity ? '' : minutes + ":" + justSeconds;
        }

        function evaluateSeekBarValue() {
            const timePosition = audio.value.currentTime - props.startTimeSeconds;
            const endPosition = smartEndTimeSeconds.value - props.startTimeSeconds;
            const seekBarPosition = (timePosition / endPosition) * 100;
            if (seekBarPosition >= 100) {
                pause();
            }
            audioTime.value = formatSeconds(timePosition);
            seekBarValue.value = seekBarPosition;
        }
        
        function play() {
            store.dispatch("setShouldPauseAudio", false);
            isPlaying.value = true;
            if(audio.value.currentTime >= smartEndTimeSeconds.value){
                audio.value.currentTime = props.startTimeSeconds;
            }
            audio.value.play();
            evaluateSeekBarValue();
            clearInterval(seekbarInterval);
            seekbarInterval = setInterval( () => {
                evaluateSeekBarValue();
            }, 100);
        }
        
        function togglePlay() {
            isPlaying.value = !isPlaying.value;
            if(isPlaying.value)
                play();
            else pause();
        }

        audio.value.addEventListener("loadedmetadata", function(event) {
            audio.value.currentTime = props.startTimeSeconds;
            if (!props.endTimeSeconds && !smartEndTimeSeconds.value) {
                if (audio.value.duration === Infinity) {
                    try {
                        audio.value.duration = 99999999; //necessary for macOS/iOS
                        audio.value.ontimeupdate = () => {
                            audio.value.ontimeupdate = null;
                            audio.value.currentTime = props.startTimeSeconds;
                            smartEndTimeSeconds.value = event.target.duration;
                        };
                    }
                    catch {
                        // above not valid in Firefox
                    }
                }
                else {
                    //smartEndTimeSeconds.value = event.target.duration;
                }
            } 
            else if (props.endTimeSeconds) {
                //smartEndTimeSeconds.value = props.endTimeSeconds;
            }
            setTimeout( () => {
                pause();
                audioTime.value = "0:00";
                seekBarValue.value = 0;
            }, 0);
        });
        
        function seek (seconds) {
            const proposedSeekTime = Math.round(audio.value.currentTime + seconds);
            if (proposedSeekTime >= smartEndTimeSeconds.value && smartEndTimeSeconds.value){
                audio.value.currentTime = smartEndTimeSeconds.value;
                pause();
            }
            else if (proposedSeekTime <= props.startTimeSeconds){
                audio.value.currentTime = props.startTimeSeconds;
            }
            else {
                audio.value.currentTime = proposedSeekTime;
            }
            evaluateSeekBarValue();
            if(audio.value.currentTime >= smartEndTimeSeconds.value && smartEndTimeSeconds.value){
                pause();
            }
        }

        function seekBarTouchStart () {    
            clearInterval(seekbarInterval);
            seekbarIsHeld = true;
        }

        function getAudioFileTime () {
            const audioLength = smartEndTimeSeconds.value - props.startTimeSeconds;
            const seekBarPercentage = seekBarValue.value / 100;
            const startTime = +props.startTimeSeconds;
            return (audioLength * seekBarPercentage) + startTime;
        }

        function seekBarTouchEnd () {
            const waitForRangeChange = () => {
                seekbarIsHeld = false;
                const timeToSet = getAudioFileTime();
                if (timeToSet >= smartEndTimeSeconds.value && smartEndTimeSeconds.value) {
                    audio.value.currentTime = smartEndTimeSeconds.value;
                    pause();
                } else {
                    audio.value.currentTime = timeToSet;
                    play();
                }
            }
            setTimeout(waitForRangeChange, 200);
        }

        function getAudioTimeForTab () {
            const audioLength = smartEndTimeSeconds.value - props.startTimeSeconds;
            const seekBarPercentage = seekBarValue.value / 100;
            return (audioLength * seekBarPercentage);
        }

        function rangeChange(){
            if(seekbarIsHeld){
                pause();
                audioTime.value = formatSeconds(getAudioTimeForTab());
            }
        }

        return {
            isPlaying,
            seekBarValue,
            seek,
            enableTransition,
            enableTransitionTimeout: null, 
            togglePlay,
            seekBarTouchStart,
            seekBarTouchEnd, 
            formatSeconds,
            audioTime,
            rangeChange,
            smartEndTimeSeconds,
            startTime: computed(() => formatSeconds(props.startTimeSeconds)),
            endTime: computed(() => formatSeconds(smartEndTimeSeconds.value - props.startTimeSeconds))
        };
    }
});
</script>

<style scoped>

.audio-player {
    display: flex;
    flex-direction: column;
    margin: 0 35px;
    padding-top: 16px;
    padding-bottom: 20px;
}

.enable-transitions {
    --transition: .1s linear;
}

.seek-time {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-text-gray);
    margin-left: 5px;
    text-align: start;
}

.audio-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    fill: var(--ion-color-primary);
    cursor: pointer;
}

#play-button, #pause-button {
    margin: 0 56px;
}

ion-range::part(knob) {
    display: none;
}

ion-range::part(range-slider) {
}

ion-range::part(bar-active) {
    transition: var(--transition);
}

ion-range {
    --height: 8px;
    --bar-height: 8px;
    --bar-border-radius: 10px;
    --bar-background: var(--ion-color-custom-step-800);
    --bar-background-active: var(--ion-color-primary);
    padding: 0;
    margin-bottom: 4px
}
</style>

