<template>
    <ion-page id="main-content">
        <ion-progress-bar :value="progress" v-if="!invalidUrl"></ion-progress-bar>
        <div v-else>
            <h1>404</h1>
            <p>The resource you requested does not exist or is no longer available.</p>
        </div>
    </ion-page>
</template>

<script lang="ts">
import { IonPage, IonProgressBar } from "@ionic/vue";
import {store} from "@/store";
import {computed} from "vue";

export default {
    name: "MainLoader",
    components: {
        IonPage,
        IonProgressBar
    },
    setup() {
        return {
            progress: computed(() => store.getters.getLoadingValue()),
            invalidUrl: computed(() => store.getters.getIsInvalidUrl())
        }
    }
}
</script>

<style scoped>
#main-content {
    display: flex;
    align-items: center;
    justify-content: center;
}
ion-progress-bar {
    width: 200px;
}

div {
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}
h1 {
    font-size: 120px;
    font-weight: 400;
    color: #333;
}
p {
    font-size: 20px;
}
</style>
