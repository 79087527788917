import { useI18n } from "vue-i18n";
import { useStore } from "vuex";


export default function() {
    const store = useStore();
    const { t, setLocaleMessage } = useI18n();
    setLocaleMessage(store.getters.getLocale(), {messages: store.getters.getLocaleMessages()});
    return { t, setLocaleMessage};
}

export function getTranslatedNumber(number: number) {
    const store = useStore();
    const messages = store.getters.getLocaleMessages();
    const numberArray = [
        messages.calculator.zero,
        messages.calculator.one,
        messages.calculator.two,
        messages.calculator.three,
        messages.calculator.four,
        messages.calculator.five,
        messages.calculator.six,
        messages.calculator.seven,
        messages.calculator.eight,
        messages.calculator.nine,
        messages.calculator.ten,
        messages.calculator.eleven,
        messages.calculator.twelve,
        messages.calculator.thirteen,
        messages.calculator.fourteen,
        messages.calculator.fifteen,
        messages.calculator.sixteen,
        messages.calculator.seventeen,
        messages.calculator.eighteen,
        messages.calculator.nineteen,
        messages.calculator.twenty
        
    ];
    return numberArray[number]; 
}
