<template>
    <ion-header class="menu-header" mode="md">
        <ion-toolbar color="app-primary" mode="md">
            <div class="nav-menu">
                <ion-icon @click="returnHome" :icon="homeSharp" class="home-image"/>
                <ion-title class="nav-title">
                    <div class="title-container image-and-text-title-container" v-if="pageTitle.length > 0">
                        <ion-icon :src="imgSrc" class="title-image" />
                        <span>{{ pageTitle }}</span>
                    </div>
                    <div class="title-container" v-else>
                        <ion-icon v-if="imgSrc" :src="imgSrc" class="title-image" />
                    </div>
                </ion-title>
                <ion-buttons slot="end">
                    <ion-menu-button auto-hide="false"><ion-icon v-if="isMenuOpen" class="close-icon" :icon="closeSharp"/></ion-menu-button>
                </ion-buttons>
            </div>
        </ion-toolbar>
    </ion-header>
    <ion-menu
        :side="dynamicSide"
        swipe-gesture="false"
        content-id="main-content"
        class="custom-menu"
        @ion-will-open="toggleMenu(true)"
        @ion-will-close="toggleMenu(false)"
        @ion-did-close="closeSubMenus"
    >
        <ion-content class="menu-content">
            <ion-list class="menu-list">
                <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-row ion-no-padding">
                        <div class="menu-item" @click="handleShareApp">
                            <div class="menu-image">
                                <ion-icon class="icon" :icon="shareSocial"/>
                            </div>
                            <p class="menu-text">{{ shareAppText }}</p>
                            <div class="menu-extras" />
                        </div>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="t('messages.menu.findCoach') !== 'messages.menu.findCoach'">
                    <ion-item class="menu-row ion-no-padding" @click="goToCoach">
                        <div class="menu-item">
                            <div class="menu-image"><ion-icon src="assets/img/person.svg" class="icon" /></div>
                            <p class="menu-text">{{ t("messages.menu.findCoach") }}</p>
                            <div class="menu-extras" />
                        </div>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="showBigPicture && showAllQuadrants">
                    <ion-item class="menu-row ion-no-padding" @click="openBigPicturePagesModal(localeMessages)">
                        <div class="menu-item">
                            <div class="menu-image"><img :src="bigPictureIcon"/></div>
                            <p class="menu-text">{{ t("messages.menu.bigPicture") }}</p>
                        </div>
                    </ion-item>
                </ion-menu-toggle>
                <ion-item class="menu-row ion-no-padding" v-if="showFindMore && !showAllQuadrants">
                    <div class="menu-item" @click="toggleFindMore">
                        <div class="menu-image"><ion-icon src="assets/img/binoculars.svg" class="icon" /></div>
                        <p class="menu-text">{{ t("messages.menu.findMore") }}</p>
                        <div class="menu-extras">
                            <ion-icon :icon="chevronUp" v-if="expandFindMore" />
                            <ion-icon :icon="chevronDown" v-else />
                        </div>
                    </div>
                </ion-item>
                <ion-list v-if="expandFindMore" class="menu-list">
                    <ion-menu-toggle auto-hide="true" v-if="showBigPicture">
                        <ion-item class="sub-menu-row ion-no-padding" @click="openBigPicturePagesModal(localeMessages)">
                            <div class="sub-menu-item">
                                <div class="menu-image"><img :src="bigPictureIcon"/></div>
                                <p class="menu-text">{{ t("messages.menu.bigPicture") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-menu-toggle auto-hide="true" v-if="showMoreTopics">
                        <ion-item class="sub-menu-row ion-no-padding">
                            <div class="sub-menu-item" @click="goToMoreTopics">
                                <div class="menu-image"><img :src="moreTopicsIcon"/></div>
                                <p class="menu-text">{{ t("messages.menu.moreTopics") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-menu-toggle auto-hide="true" v-if="showLearnMore">
                        <ion-item class="sub-menu-row ion-no-padding">
                            <div class="sub-menu-item" @click="goToLearnMore">
                                <div class="menu-image"><img :src="learnMoreIcon"/></div>
                                <p class="menu-text">{{ t("messages.menu.learnMore") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
                <ion-menu-toggle auto-hide="true">
                    <ion-item class="menu-row ion-no-padding">
                        <label for="encryptedFile">
                            <div class="menu-item">
                                <div class="menu-image"><ion-icon src="assets/img/systemUpdate.svg" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.import") }}</p>
                                <div class="menu-extras" />
                            </div>
                        </label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-menu-toggle auto-hide="true" v-if="showUnencrypted">
                    <ion-item class="menu-row ion-no-padding">
                        <label for="unencryptedFile">
                            <div class="menu-item">
                                <div class="menu-image"><ion-icon src="assets/img/systemUpdate.svg" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.importUnencrypted") }}</p>
                                <div class="menu-extras" />
                            </div>
                        </label>
                    </ion-item>
                </ion-menu-toggle>
                <ion-item class="menu-row ion-no-padding">
                    <div class="menu-item" @click="toggleSettings">
                        <div class="menu-image"><ion-icon src="assets/img/settings.svg" class="icon" /></div>
                        <p class="menu-text">{{ t("messages.menu.settings") }}</p>
                        <div class="menu-extras">
                            <ion-icon :icon="chevronUp" v-if="showSettings" />
                            <ion-icon :icon="chevronDown" v-else />
                        </div>
                    </div>
                </ion-item>
                <ion-list v-if="showSettings" class="menu-list">
                    <ion-menu-toggle auto-hide="true">
                        <ion-item class="sub-menu-row ion-no-padding" @click="goToSecurity">
                            <div class="sub-menu-item">
                                <div class="menu-image"><ion-icon src="assets/img/lock.svg" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.security") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-menu-toggle auto-hide="true">
                        <ion-item class="sub-menu-row ion-no-padding" @click="goToPrivacy">
                            <div class="sub-menu-item">
                                <div class="menu-image"><ion-icon src="assets/img/privacy.svg" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.privacy") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    <!-- Removing the check for updates in the secure version temporarily , 
                         this can be put back in when the update mechanism is fixed
                    -->
                    
                    <ion-menu-toggle auto-hide="true" v-if="isOfflineAndroid">
                        <ion-item class="sub-menu-row ion-no-padding" @click="checkForUpdatesPreCheck">
                            <div class="sub-menu-item">
                                <div class="menu-image"><ion-icon :icon="cloudDownload" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.checkForUpdates") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    
                    <ion-menu-toggle auto-hide="true" v-if="platform === 'android'">
                        <ion-item class="sub-menu-row ion-no-padding" @click="deleteAndroidFiles">
                            <div class="sub-menu-item">
                                <div class="menu-image"><ion-icon :icon="sparkles" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.cleanFiles") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                    <ion-menu-toggle auto-hide="true" v-if="platform === 'android'">
                        <ion-item class="sub-menu-row ion-no-padding" @click="deleteApp">
                            <div class="sub-menu-item">
                                <div class="menu-image"><ion-icon :icon="exit" class="icon" /></div>
                                <p class="menu-text">{{ t("messages.menu.uninstall") }}</p>
                            </div>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
                <input
                    v-if="showUnencrypted"
                    class="hidden-input"
                    type="file"
                    id="unencryptedFile"
                    accept="application/json"
                    @click="setShouldExitApp"
                    @change="importJourneySets($event, false)"
                    multiple
                />
                <input
                    class="hidden-input"
                    type="file"
                    id="encryptedFile"
                    accept="text/plain,.bin"
                    @click="setShouldExitApp"
                    @change="importJourneySets($event, true)"
                    multiple
                />
            </ion-list>
        </ion-content>
    </ion-menu>
</template>

<script lang="ts">
import {
    IonContent,
    IonHeader,
    IonToolbar,
    IonMenu,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonItem,
    IonList,
    IonIcon,
    IonMenuToggle,
    menuController
} from "@ionic/vue";
import {computed, defineComponent, onBeforeMount, ref} from "vue";
import { useRouter } from "vue-router";
import { chevronDown, chevronUp, person, shareSocial, heart, documentTextSharp, closeSharp, homeSharp, leaf, save, cloudDownload, exit, sparkles } from "ionicons/icons";
import translate from "../views/translate";
import {
    createLoadSpinner,
    readAndSaveJourneySets
} from "@/services/ShareService";
import { openBigPicturePagesModal } from "@/services/InformationPagesService";
import { useStore } from "vuex";
import {downloadAPK, getFromS3} from "@/services/AWSService";
import { connectToInternetAlert} from "@/utilities/Alerts";
import {ActionSheetBuilder} from "@/utilities/ActionSheetBuilder";
import { AlertBuilder } from "@/utilities/AlertBuilder";
import {isValidJourneySetFilename, getJourneySetImportErrors} from "@/utilities/JourneySet";
import {getDBVersion,reloadManifestMetaData} from "@/storage/DataStorage";
import {checkAndRequestStoragePermission, deleteAndroidFiles, deleteApp} from "@/utilities/Filesystem";
import {getImageDataUri} from "@/utilities/Compatibility";

export default defineComponent({
    name: "main-menu",
    props: ["pageTitle", "imgSrc"],
    components: {
        IonContent,
        IonHeader,
        IonToolbar,
        IonMenu,
        IonTitle,
        IonItem,
        IonButtons,
        IonMenuButton,
        IonList,
        IonIcon,
        IonMenuToggle,
    },
    setup() {
        const { t } = translate();
        const store = useStore();
        const dynamicSide = computed(() => t("messages.language.textDirection") === "ltr" ? "end" : "start");
        const showUnencrypted = computed(() => process.env.NODE_ENV === "development");
        const expandFindMore = ref(false);
        const showSettings = ref(false);
        const showBigPicture = computed(() => t("messages.buildConfigs.config.bigPic") === "true");
        const showLearnMore = computed(() => t("messages.buildConfigs.config.learnMore") === "true");
        const showMoreTopics = computed(() => t("messages.buildConfigs.config.moreTopics") === "true");
        const showFindMore = computed(() => showBigPicture.value || showLearnMore.value || showMoreTopics.value);
        const platform = ref('');
        const isOfflineAndroid = computed(() => platform.value === "android" && t("messages.buildConfigs.config.appStoreBuild") !== "true")
        
        onBeforeMount(async () => {
            platform.value = await store.getters.platform();
        })

        const toggleFindMore = () => {
            showSettings.value = false;
            expandFindMore.value = !expandFindMore.value;
        };

        const toggleSettings = () => {
            expandFindMore.value = false;
            showSettings.value = !showSettings.value;
        };

        const isMenuOpen = ref(false);

        const toggleMenu = (value: boolean) => {
            isMenuOpen.value = value;
        };

        const closeSubMenus = () => {
            expandFindMore.value = false;
            showSettings.value = false;
        };
        const router = useRouter();
        const route = computed(() => router.currentRoute.value);

        const returnHome = async () => {
            await menuController.close();
            await router.push({ name: `fields` });
        };

        const goToPrivacy = () => {
            router.push({ name: `privacy` });
        };

        const goToLearnMore = () => {
            router.push({ name: "learn-more", params: { fieldId: "jf5" } });
        };

        const goToMoreTopics = () => {
            router.push({ name: "more-topics", params: { fieldId: "jf6" } });
        };

        const goToSecurity = () => {
            router.push({ name: `security` });
        };

        const goToCoach = () => {
            router.push({ name: `coach` });
        };
        
        const askToUpdate = async () => {
            const alert = new AlertBuilder('custom-alert', `<p>${t('messages.application.updateApp')}</p>`)
                .addNoButton()
                .addYesButton(async () => {
                    const loading = await createLoadSpinner();
                    await loading.present();
                    await downloadAPK(false);
                    await loading.dismiss();
                });
            await alert.present();
        }
        //This function should maybe be moved and expanded to handle all checks for updates (online apps launch as well as being triggered from the menu)?
        const checkForUpdates = async () => {
            const result = await getFromS3(t('messages.language.variant'), `appVersion.txt?timestamp=${new Date().getTime()}`, 'text');
            const mostRecentAppVersion = result.data;
            store.dispatch("setSecureUpdateOverride" , true);
            await reloadManifestMetaData();
            //await reloadLanguage();
            store.dispatch("setSecureUpdateOverride" , false);
            if (getDBVersion() < parseInt(mostRecentAppVersion, 10)) {
                await askToUpdate();
            } else {
                const alert = new AlertBuilder('custom-alert', `<p>${t('messages.application.noUpdatesAvailable')}</p>`).addOkButton();
                await alert.present();
            }
        }
        
        const checkForUpdatesPreCheck = async () => {
            const shouldDisplayInternetAlert = await connectToInternetAlert(async () => {
                await checkForUpdates();
            });
            if (!shouldDisplayInternetAlert) {
                await checkForUpdates();
            }
        }

        const setShouldExitApp = async () => {
            if(store.getters.platform() === "android"){
                if(await checkAndRequestStoragePermission()){
                    await store.dispatch("setShouldExitApp", false);
                    return true;
                }else{
                    return false;
                }
            }
            return true;
        };
        
        const importJourneySets = async (event: any, shouldDecrypt: boolean) => {
            await store.dispatch("setShouldExitApp", true);
            let showSuccessMessage = false;
            if (event.target.files.length > 0) {
                const loading = await createLoadSpinner();
                await loading.present();
                let error = '';
                for (const file of event.target.files) {
                    let fileError = '';
                    if (isValidJourneySetFilename(file.name)) {
                        try {
                            await new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsText(file, "UTF-8");
                                reader.onload = async (setToBeAdded) => {
                                    try {
                                        const journeySet = JSON.parse(setToBeAdded?.target?.result as string);
                                        const importErrors = getJourneySetImportErrors(journeySet);
                                        if (importErrors === '') {
                                            await readAndSaveJourneySets(journeySet, shouldDecrypt);
                                            showSuccessMessage = true;
                                            resolve(true);
                                            return;
                                        }
                                        fileError = importErrors.replace('[%filename]', file.name);
                                        reject("invalid");
                                    } catch (e) {
                                        fileError = t('messages.application.importErrorProcessing').replace('[%filename]', file.name);
                                        reject("error");
                                    }
                                };
                                reader.onerror = function(evt) {
                                    fileError = t('messages.application.importErrorReading').replace('[%filename]', file.name);
                                    // TODO: MAYBE SOME AWS LOGGING SOMETIME DOWN THE ROAD?
                                    reject(evt?.target?.result);
                                };
                            });
                        } catch (e) {
                            await loading.dismiss();
                            fileError = fileError ?? t('messages.application.importErrorProcessing').replace('[%filename]', file.name);
                        }
                    } else {
                        error = t('messages.application.importInvalidFile').replace('[%filename]', file.name);
                    }
                    error += fileError ? fileError + '<br /><br />' : '';
                }
                if (error) {
                    const alert = new AlertBuilder('custom-alert', error).addCancelButton();
                    await alert.present();
                } else if (showSuccessMessage) {
                    const alert = new AlertBuilder('custom-alert', 
                `<p>${t("messages.application.successfulImport")}</p>
                        <p>${t("messages.application.deleteSet")}</p>`).addCancelButton();
                    await alert.present();
                    
                    // need to reload the page to get new journey sets
                    if (["journey-sets", "more-topics", "learn-more"].includes(route.value.name as string)) {
                        router.push({name: 'fields'});
                    }
                }
                await loading.dismiss();
            }
            event.target.value = '';
        };

        async function handleShareApp() {
            const actionSheetBuilder = new ActionSheetBuilder('');
            await actionSheetBuilder.buildShareOrSaveApp();
            const actionSheet = await actionSheetBuilder.create();
            await actionSheet.present();
        }
        
        return {
            t,
            toggleFindMore,
            expandFindMore,
            toggleSettings,
            showSettings,
            chevronDown,
            chevronUp,
            person,
            shareSocial,
            heart,
            save,
            documentTextSharp,
            toggleMenu,
            isMenuOpen,
            closeSharp,
            closeSubMenus,
            homeSharp,
            returnHome,
            goToPrivacy,
            goToLearnMore,
            goToMoreTopics,
            goToCoach,
            goToSecurity,
            leaf,
            openBigPicturePagesModal,
            localeMessages: computed(() => store.getters.getLocaleMessages()),
            importJourneySets,
            setShouldExitApp,
            showFindMore,
            showBigPicture,
            showLearnMore,
            showMoreTopics,
            shareAppText: computed(() => platform.value === "ios" || store.getters.getLocaleMessages().buildConfigs.config.appStoreBuild === 'true' ? t("messages.application.shareAppOnly"): t("messages.menu.shareApp")),
            handleShareApp,
            dynamicSide,
            showUnencrypted,
            cloudDownload,
            checkForUpdatesPreCheck,
            isOfflineAndroid,
            showAllQuadrants: computed(() => store.getters.showAllQuadrants()),
            platform,
            exit,
            sparkles,
            deleteApp,
            deleteAndroidFiles,
            bigPictureIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.bigPictureIcon)),
            moreTopicsIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.moreTopicsIcon)),
            learnMoreIcon: computed(() => getImageDataUri(store.getters.getLocaleMessages().menu.learnMoreIcon))
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    height: calc(56px + var(--ion-safe-area-top));
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.home-image {
    margin-left: 20px;
    margin-right: 8px;
    font-size: 25px;
}

.title-image {
    text-align: center;
    font-size: 30px;
}

.nav-title {
    text-align: center;
    font-size: 18px;
}

.title-container {
    display: inline-flex;
}

.image-and-text-title-container {
    vertical-align: middle;
}
.image-and-text-title-container span {
    font-size: 18px;
    font-weight: bold;
    line-height: 25px;
}

.image-and-text-title-container .title-image {
    font-size: 25px;
    margin: 0 5px;
}

.image-and-text-title-container span {
    margin: 0 5px;
}


.menu-header {
    z-index: 1001;
}

/* NEED TO DECLARE THESE EMPTY CLASSES TO ACCESS CSS SHADOW PARTS*/
.menu-row {
}
.menu-row::part(native),
.sub-menu-row::part(native) {
    --border-color: var(--ion-color-custom-step-700);
}

.sub-menu-row {
    --background: var(--color-app-background);
}

.menu-list {
    padding: 0;
}

.custom-menu {
    --width: 300px;
    padding: 0;
}

.custom-menu::part(container) {
    top: calc(56px + var(--ion-safe-area-top));
}

/* NEED TO DECLARE THESE EMPTY CLASSES TO ACCESS CSS SHADOW PARTS*/
.menu-content {
}
.menu-content::part(scroll) {
    padding-top: 0;
}

.menu-image {
    margin: 0 25px;
    flex-basis: 25px;
    flex-grow: 0;
    flex-shrink: 0;
}

.menu-text {
    width: 100%;
    font-size: 18px;
    color: var(--ion-color-custom-step-250);
    font-weight: 400;
    text-align: start;
}

.menu-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.sub-menu-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.sub-menu-item .menu-image {
    margin: 0 25px 0 45px;
}

.icon {
    color: var(--color-primary);
    font-size: 25px;
}

ion-menu-button::part(icon) {
    font-size: 30px;
}

.close-icon {
    color: var(--ion-color-custom-step-1000);
    font-size: 40px;
}
.hidden-input {
    display: none;
}
</style>
