import {store} from "@/store";
import {alertController} from "@ionic/vue";
import {AlertOptions, AlertInput, AlertButton} from '@ionic/core';

export class AlertBuilder {
    private language = store.getters.getLocaleMessages();
    alertOptions = { } as AlertOptions;

    constructor(cssClass: string, message: string, subHeader = '') {
        this.alertOptions.cssClass = cssClass;
        this.alertOptions.message = `<p>${ message }</p>`;
        this.alertOptions.inputs = [] as AlertInput[];
        this.alertOptions.buttons = [] as AlertButton[];
        if (subHeader) {
            this.alertOptions.subHeader = subHeader;
        }
    }

    addCancelButton() {
        this.alertOptions.buttons!.push({
            text: this.language.application.close,
            cssClass: 'alert-button-no',
            role: 'cancel',
        });
        return this;
    }

    addNoButton() {
        this.alertOptions.buttons!.push({
            text: this.language.application.no,
            cssClass: 'alert-button-no',
            role: 'cancel',
        });
        return this;
    }

    addYesButton(handler: () => any) {
        this.alertOptions.buttons!.push({
            text: this.language.application.yes,
            cssClass: 'alert-button-yes',
            handler: () => handler()
        });
        return this;
    }

    addOkButton() {
        this.alertOptions.buttons!.push({
            text: this.language.application.okay,
            cssClass: 'alert-button-yes',
            role: 'cancel'
        });
        return this;
    }
    
    addCustomButton(handler: () => any, text: string, cssClass: string) {
        this.alertOptions.buttons!.push({
            text: text,
            cssClass: cssClass,
            handler: () => handler()
        });
        return this;
    }

    async create() {
        return await alertController.create(this.alertOptions);
    }

    async present() {
        const alert = await this.create();
        await alert.present();
    }
} 