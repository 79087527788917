import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_progress_bar = _resolveComponent("ion-progress-bar")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { id: "main-content" }, {
    default: _withCtx(() => [
      (!$setup.invalidUrl)
        ? (_openBlock(), _createBlock(_component_ion_progress_bar, {
            key: 0,
            value: $setup.progress
          }, null, 8, ["value"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
            _createElementVNode("h1", null, "404", -1),
            _createElementVNode("p", null, "The resource you requested does not exist or is no longer available.", -1)
          ])))
    ]),
    _: 1
  }))
}